'use strict'

loadAsyncJS = require('../../../../../src/frontend/helper/general/m_loadAsyncJS')
{ getFBConsentsPayload, getLegalVersionsFromDOM } = require('./helpers')
fbId = 'facebook-jssdk'
fbUrl = '//connect.facebook.net/en_US/sdk.js'

Facebook =
  init: (signinFlyout) ->
    return if not ($fbButton = $('#ls-btn-facebook')).length
    @signinFlyout = signinFlyout
    @$error = $('#ls-signin-error')
    cb = =>
      $fbButton.on 'click', => @signIn()
      $('#fb-auth').on 'click', =>
        GIG.Modal.close()
        @signIn(true)
      $('#fb-auth-close').on 'click', ->
        GIG.Modal.close()

    if document.getElementById fbId
      cb()
    else
      loadAsyncJS fbUrl, fbId, cb


  signIn: (reauth = false) ->
    @$error.text('').hide()
    option = {
      scope: 'email,user_friends,user_hometown',
      return_scopes: true
    }
    Object.assign(option, { auth_type: 'rerequest' }) if reauth
    FB.login (res) =>
      if res?.status is 'connected'
        @loginOrConnect(res)
      else
        @onError('facebook_side_error')
    , option

  loginOrConnect: (res) ->
    if res?.authResponse
      { grantedScopes } = res.authResponse
      if grantedScopes?.indexOf('email') > -1
        FB.api '/me', {
          fields: 'id,name,email,first_name,last_name,picture.type(square)'
        }, (userInfo) =>
          @save(res, userInfo)
      else
        GIG.Modal.open
          id: 'ls-fb-auth-modal'
    else
      @onError()

  save: (res, userInfo) ->
    { accessToken, signedRequest } = res.authResponse
    $csrf = $("input[name='_csrf']")

    data =
      _csrf: $csrf?.val?() or ''
      access_token: accessToken
      signed_request: signedRequest
      email: userInfo.email
      remember_me: !!$('#ls-remember-me').prop('checked')

    if Groupon.LS.experiments?.showGDPRSigninFlyout
      { termsVersion, privacyVersion } = getLegalVersionsFromDOM()
      legalConsents = getFBConsentsPayload(privacyVersion, termsVersion)
      data.legalConsents = legalConsents

    $.ajax
      type: 'POST'
      url: '/facebook'
      data: data
      success: (resData) =>
        @signinFlyout.successMetric()
        redirectUrl = resData?.location || window.location.href
        Groupon.setWindowLocation redirectUrl
      error: (errMsg) =>
        @onError(errMsg)

  onError: ->
    @$error.text(Groupon.LS.translations.userNav.signInflyoutUnknownError).show()

module.exports = Facebook
