'use strict'

unparam = require './unparam'
getConfigFromDom          = require './getConfigFromDom'
trackEvent                = require './trackEvent'
makeTag                   = require '../../helper/general/m_makeTag'
loadAsyncJS               = require '../../helper/general/m_loadAsyncJS'
{ detectCurrentLocation } = require '../../helper/search/helper/m_detectCurrentLocation'
GoogleMaps                = require '../../helper/general/m_google_maps'
extendOnDocumentReady     = require './groupon_extended'

{ externalObjectLoader
, externalObjectLoaderOnready
} = require './externalObjectLoader'

# init Groupon object
window.Groupon ?= {}

Object.assign window.Groupon, {
  SharedJS: {}
  Widgets: {}
  PageFlags: {}
}

# For desktop, LS has been init in the head.
# This is not the case for Touch.
hasTouch = Modernizr?.touchevents?.valueOf() or 'ontouchstart' of window or window.navigator.maxTouchPoints or (window.DocumentTouch and document instanceof DocumentTouch)
events = if hasTouch
  'touchstart'
else
  'click'

pageWidths =
  xxl: 1280
  xl: 1080
  slsL: 769
  l: 720
  m: 600
  s: 400

mediaQueries =
  xxlMin: "(min-width: #{pageWidths.xxl}px)"
  xlMin: "(min-width: #{pageWidths.xl}px)"
  slsLMin: "(min-width:  #{pageWidths.slsL}px)"
  lMin: "(min-width:  #{pageWidths.l}px)"
  mMin: "(min-width:  #{pageWidths.m}px)"
  sMin: "(min-width:  #{pageWidths.s}px)"
  xxlMax: "(max-width: #{pageWidths.xxl - 1}px)"
  xlMax: "(max-width: #{pageWidths.xl - 1}px)"
  slsLMax: "(max-width:  #{pageWidths.slsL - 1}px)"
  lMax: "(max-width:  #{pageWidths.l - 1}px)"
  mMax: "(max-width:  #{pageWidths.m - 1}px)"
  sMax: "(max-width:  #{pageWidths.s - 1}px)"

window.Groupon.LS ?= {}
Object.assign window.Groupon.LS, {
  hasTouch: hasTouch
  events: events
  mediaQueries: mediaQueries
  pageWidths
  Header: {}
}

# For desktop, Modules has been init in the head.
# This is not the case for Touch.
window.Groupon.Modules ?= {}

window.Groupon.Utils ?= {}

Object.assign Groupon.Utils, {
  unparam
  makeTag
  getConfigFromDom
  externalObjectLoader
  externalObjectLoaderOnready
  GoogleMaps
  trackEvent
  loadAsyncJS
  detectCurrentLocation
}

extendOnDocumentReady()
