'use strict';

exports.domReady = fn => {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    // https://developer.mozilla.org/en-US/docs/Web/Events/DOMContentLoaded
    document.addEventListener('DOMContentLoaded', fn);
  }
};

exports.windowLoad = fn => {
  if (document.readyState === 'complete') {
    fn();
  } else {
    window.addEventListener('load', fn, { once: true });
  }
};

const thenify = (type, readyState) => {
  return new Promise(resolve => {
    const listener = () => {
      if (readyState.test(document.readyState)) {
        document.removeEventListener(type, listener);

        resolve();
      }
    };

    document.addEventListener(type, listener);

    listener();
  });
};

// export thenfied parsed, contentLoaded, and loaded
exports.parsed = thenify('readystatechange', /^(?:interactive|complete)$/);
exports.contentLoaded = thenify(
  'DOMContentLoaded',
  /^(?:interactive|complete)$/
);
exports.loaded = thenify('readystatechange', /^complete$/);
