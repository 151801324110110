'use strict'

{ throttle } = require('throttle-debounce')

backToTopButton = class
  constructor: ->
    return unless (@back2top = document.getElementById('ls-back-to-top'))
    # show when the header is not visible anymore
    @offset = document.querySelector('.header-v2 header').offsetHeight * 1.5
    @visible = false
    @bind()

  requestTick: =>
    window.requestAnimationFrame( @showOrHide )

  showOrHide: =>
    # y scroll position: modern browsers, IE9+
    y = window.scrollY or window.pageYOffset
    threshold = y > @offset

    if threshold and not @visible
      # set z-index before opacity change so we see transition
      @back2top.classList.add('ls-back-to-top-visible')
      @back2top.setAttribute('aria-hidden', 'false')
      @visible = true
    else if @visible and not threshold
      @back2top.setAttribute('aria-hidden', 'true')
      @back2top.classList.remove('ls-back-to-top-visible')
      @visible = false

  bind: =>
    window.addEventListener('scroll', throttle(1000, @requestTick), { passive: true })

    @back2top.addEventListener('click', =>
      @back2top.addEventListener('transitionend', =>
        @back2top.classList.remove('ls-back-to-top-visible')
      , { once: true, passive: true })

      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })

      @back2top.setAttribute('aria-hidden', 'true')

    , { passive: true })

module.exports = Groupon.Widgets.BackToTopButton = backToTopButton
