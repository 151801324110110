'use strict'

AriaMenuNavigation = require '../../../../../src/frontend/old/groupon_modules/ariaKeyNavigation'
PrimaryNav = require '../../../../../modules/gig_body/header/frontend/views/pn_primarynav'

module.exports = class PNHeaderV2 extends AriaMenuNavigation
  constructor: ->
    if not document.getElementById('ls-lite-header')
      new PrimaryNav()

  bind: ->
    if userName = document.querySelector('.user-name')
      $userName = $(userName)
      userMenu = document.getElementById('ls-my-stuff')
      $userMenu = $(userMenu)

      # LS-1179 make flyout accessible for touch
      if Groupon.LS.hasTouch

        $userName.on 'lstouch', (e) ->
          return if e.lsDoubleTouch

          e.preventDefault()
          e.stopImmediatePropagation()
          if $userMenu.is(':visible')
            $userMenu.hide()
          else
            $userMenu.show()
          $userMenu.attr( 'aria-hidden', $userMenu.attr('aria-hidden') isnt 'true')
          # close other header menus
          $('html').trigger 'tap'
          if $userMenu.is(':visible')
            # unbind event first, then register closing event once
            $('html').off('tap.username').one 'tap.username', (event) ->
              if $(event.target).closest('.user-name, .my-stuff').length == 0
                $userMenu.hide().attr('aria-hidden', 'true')
      else
        @initAriaMenuNavigation(userName, userMenu, userMenu.querySelectorAll('a'))
