import { h, Component } from 'preact';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
import timeAgoTranslations from 'app/common/helpers/timeAgoTranslations';

export default class TimeAgo extends Component {
  constructor(props, context) {
    super(props, context);
    this.timer = null;
  }

  componentDidMount() {
    this.timer = setInterval(this.forceUpdate.bind(this), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { time } = this.props;
    if (!time) {
      return null;
    }

    return (
      <span className="time">
        {distanceInWordsStrict(time * 1000, new Date(), {
          locale: timeAgoTranslations.locale,
        })}
      </span>
    );
  }
}
