'use strict'

lazyloaderV2 = require './grpn-lazyload-v2'

testJQuery = (variable) ->
  typeof variable is 'object' and variable.jquery

isSelector = (variable) ->
  typeof variable is 'string' or testJQuery(variable)

# we have to safe and eventually build Groupon.Utils
window.Groupon = (window.Groupon || {})
window.Groupon.Utils = (window.Groupon.Utils || {})

setupLazyloader = ->
  triggerClass = '.lazy-load'

  loader = lazyloaderV2.init()

  Groupon.Utils.lazyLoadImages = (args...) ->
    [arg1] = args if args.length is 1
    [arg1, arg2] = args if args.length > 1

    if arg1
      if isSelector(arg1)
        customClass = arg1
      else
        if typeof arg1 is 'object'
          options = arg1

    if arg2
      if isSelector(arg2)
        customClass = arg2
      else
        if typeof arg2 is 'object'
          options = arg2

    if customClass
      if typeof customClass is 'string'
        trigger = customClass ? triggerClass
      else
        # jquery object
        if testJQuery(customClass)
          return unless customClass.length
          trigger = customClass.selector or triggerClass
    else
      trigger = triggerClass

    if trigger
      loader(trigger, options or {})

module.exports = setupLazyloader()
