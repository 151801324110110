'use strict'

###
# LS-2925 sticky desktop header
###
header = undefined
fn = undefined
lastY = 0
offset = 0
tick = false
stickyClass = 'ls-sticky-search'
hasStickyHeader = false
isSearchPage = false

###
# Bind sticky header method from scroll event
# Remove mouse enter event
# NOTE: function is used externally
###
setEventListener = ->
  return unless hasStickyHeader # NEEDED
  window.addEventListener 'scroll', rtChannel, {passive: true}
  return unless header
  header.addEventListener 'mouseenter', removeStickyClass, {passive: true} # FIXME: change this to pointer event (LS-2806)
  return

###
# Remove sticky header method to scroll event
# Add event to show the channel nav, when mouse enters header
# NOTE: function is used externally
###
removeEventListener = ->
  return unless hasStickyHeader # NEEDED
  window.removeEventListener 'scroll', rtChannel, {passive: true}
  return unless header
  header.removeEventListener 'mouseenter', removeStickyClass, {passive: true} # FIXME: change this to pointer event (LS-2806)
  header.classList.remove(stickyClass)
  return

###*
# Callback to remove sticky class
#
# @callback removeStickyClass
###
removeStickyClass = ->
  this.classList.remove(stickyClass)
  return

###*
# Scroll callback
#
# @callback rtChannel
###
rtChannel = ->
  if not tick
    fn( onScroll )
  tick = true
  return

###*
# Callback animating the header elements
#
# @callback onScroll
###
onScroll = ->
  y = window.scrollY or window.pageYOffset
  if lastY < y and y > offset
    header.classList.add(stickyClass)
  else if lastY > y or y < offset
    header.classList.remove(stickyClass)
  lastY = y
  tick = false
  return

###*
# Test if sticky header should be applied
#
# @returns {boolean}
###
shouldApply = ->
  return false unless isSearchPage
  return false unless ($header = $('.header-v2')).length
  return false unless $header.hasClass('ls-sticky-header')

  true

stickyHeader =
  init: ->
    if Groupon.LS?.flags?.isSearchPage
      isSearchPage = true
      $('.header-v2').addClass('ls-sticky-header')

    return unless (hasStickyHeader = shouldApply()) # test and bind result to closure

    header = $('.header-v2')[0]

    fn = (cb) -> window.requestAnimationFrame( cb )

    lastY = window.scrollY or window.pageYOffset
    offset += 1

    setEventListener()

    return

  pause:  -> removeEventListener()
  resume: -> setEventListener()

module.exports = stickyHeader
