'use strict'

# see http://bjk5.com/post/44698559168/breaking-down-amazons-mega-dropdown
#
# this file is a stripped down coffeescript version with minor tweaks of
# the open source jquery-menu-aim.  it only supports submenus that open
# from the right

MAX_MOUSE_LOCS = 3
FALLBACK_DELAY = 300
mouseLocs = []
documentListener = null # only need one listener on document

noop = ->

mousemoveDocument = (e) ->
  mouseLocs.push { x: e.pageX, y: e.pageY }
  mouseLocs.shift() if mouseLocs.length > MAX_MOUSE_LOCS

getSlope = (pointA, pointB) ->
  (pointB.y - pointA.y) / (pointB.x - pointA.x)

###*
# Return instance of flyout with triangle algorithm
#
# @param   {Object} [options]
# @returns {Object} - instance of flyout with activateRow and deactivateRow methods
###
module.exports = (options) ->
  {
    menuElem, # container (ul) elem with nested "rowSelector" elems
    rowSelector, # selector string to apply event handlers within $menuElem
    delay, # delay on hover before activating hovered row (when within triangle)
    onActivateRow, # event handler passing in now active elem
    onDeactivateRow, # event handler passing in now (previously active) inactive elem
  } = options

  $menuElem = $(menuElem)
  return unless $menuElem?.find(rowSelector).length

  onActivateRow ?= noop
  onDeactivateRow ?= noop

  activeRow = null
  activateRowTimeout = null
  lastDelayLoc = null # last location where timeout was set
  delay ?= FALLBACK_DELAY

  mouseleaveMenu = clearExistingTimeout = ->
    clearTimeout(activateRowTimeout) if activateRowTimeout

  ###*
  # Set active row within a menu
  #
  # @param   {Object} - jQuery row elem to set active within a flyout instance
  # @returns {Object} - jQuery row elem that is now active
  ###
  activateRow = (newActiveRow) ->
    return if newActiveRow is activeRow
    onDeactivateRow(activeRow) if activeRow
    onActivateRow(newActiveRow)
    activeRow = newActiveRow

  ###*
  # Deactivate current active row
  #
  # @returns {null}
  ###
  deactivateRow = ->
    onDeactivateRow(activeRow) if activeRow
    activeRow = null

  getActivationDelay = ->
    return 0 if !activeRow

    offset = $menuElem.offset()
    menuUpperRight =
      x: offset.left + $menuElem.outerWidth()
      y: offset.top - 75
    menuBottomRight =
      x: menuUpperRight.x
      y: offset.top + $menuElem.outerHeight() + 75

    return 0 unless (currentLoc = mouseLocs[mouseLocs.length - 1])?
    prevLoc = mouseLocs[0]

    return 0 if prevLoc.x < offset.left or
    prevLoc.x > menuBottomRight.x or
    prevLoc.y < offset.top or
    prevLoc.y > menuBottomRight.y

    return 0 if lastDelayLoc and
    currentLoc.x is lastDelayLoc.x and
    currentLoc.y is lastDelayLoc.y

    decreasingSlope = getSlope(currentLoc, menuUpperRight)
    increasingSlope = getSlope(currentLoc, menuBottomRight)
    prevDecreasingSlope = getSlope(prevLoc, menuUpperRight)
    prevIncreasingSlope = getSlope(prevLoc, menuBottomRight)

    if decreasingSlope < prevDecreasingSlope and
    increasingSlope > prevIncreasingSlope
      lastDelayLoc = currentLoc
      delay
    else
      lastDelayLoc = null
      0

  possiblyActivateRow = (rowElem) ->
    activationDelay = getActivationDelay()

    if activationDelay
      activateRowTimeout = setTimeout (->
        possiblyActivateRow rowElem
      ), activationDelay
    else
      activateRow rowElem

  mouseenterRow = ->
    clearExistingTimeout()
    possiblyActivateRow this

  $menuElem
    .on('mouseleave', mouseleaveMenu)
    .find(rowSelector)
    .on('mouseenter', mouseenterRow)

  if not documentListener
    documentListener = $(document).on 'mousemove', mousemoveDocument

  {activateRow, deactivateRow}
