'use strict'

###*
# Get first address component by type
#
# @param   {Object} address_components[] - Set of addresses
# @param   {String} component_type       - Type to retrieve
# @returns {Object|undefined}            - First address result matching type
###
getAddressComponentType = ({ address_components }, component_type) ->
  address_components.find ({ types }) ->
    if types?
      types.some (type) -> type is component_type
    else
      false

###*
# Get address name
#
# @param   {Object} result                      - Google maps result object
# @param   {Array}  result.address_components[] - Array with address_components describing result
# @param   {String} result.formatted_address    - Localized nice name for result
# @returns {String}
###
getAlternateAddressFormat = (result) ->
  if (address = getAddressComponentType(result, 'sublocality_level_1'))?
    address.short_name
  else if (address = getAddressComponentType(result, 'locality'))?
    address.long_name
  else if (address = getAddressComponentType(result, 'administrative_area_level_1'))?
    address.short_name
  else
    result.formatted_address

module.exports = {
  getAddressComponentType
  getAlternateAddressFormat
}
