'use strict'

TEN_YEARS_IN_MS = 1000 * 60 * 60 * 24 * 365 * 10
Groupon.setWindowLocation = require '../../helper/general/m_setWindowLocation'

# we have to safe and eventually build Groupon.Utils
window.Groupon = (window.Groupon || {})
window.Groupon.Utils = (window.Groupon.Utils || {})

module.exports = Groupon.Utils.changeUserLanguage = (language) ->
  Cookie.set 'user_locale', language, { maxAge: TEN_YEARS_IN_MS, consentClass: "essential" }
  hreflang = "[hreflang='" + language.replace('_', '-') + "']"

  if (alternate = $("head link" + hreflang).attr("href"))
    href = alternate
  else
    href = '/'

  Groupon.setWindowLocation href
