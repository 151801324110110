'use strict'

###*
# replace html entities
#
# @param   {String} str
# @returns {String}
###
escape = (str) ->
  encodeEntityMap = {
    '&': '&amp;'
    '<': '&lt;'
    '>': '&gt;'
    '"': '&quot;'
    "'": '&#39;'
    '`': '&#96;'
  }
  characters = new RegExp '[' + Object.keys(encodeEntityMap).join('') + ']', 'g'

  str.replace characters, (s) -> encodeEntityMap[s]

unescape = (str) ->

  decodeEntityMap = {
    '&amp;': '&'
    '&#38;': '&'
    '&#x26;': '&'
    '&lt;': '<'
    '&gt;': '>'
    '&quot;': '"'
    '&#34;': '"'
    '&#x22;': '"'
    '&#39;': "'"
    '&#x27;': "'"
    '&#96;': '`'
    '&#x60;': '`'
  }
  characters = new RegExp '(' + Object.keys(decodeEntityMap).join('|') + ')', 'g'

  str.replace characters, (s) -> decodeEntityMap[s]

###*
# Flatten array
#
# @param   {Array}   arr     - Array to be deep flattened
# @param   {boolean} shallow - Flatten only shallow
# @returns {Array}
###
flatten = (arr, shallow = false) ->
  return [].concat(arr...) if shallow
  return flattenDeep(arr)

###*
# Deep flattening array
#
# @param   {Array} arr
# @param   {Array} [res]
# @returns {Array} res
# @recursive
###
flattenDeep = (arr, res = []) ->
  arr.forEach (value) ->
    if Array.isArray(value)
      flattenDeep(value, res)
    else
      res.push(value)
  res

###*
# Determine if iteratee is empty
#
# @param   {*}       iteratee
# @returns {boolean}
###
isEmpty = (iteratee) ->
  if Array.isArray(iteratee)
    return not iteratee.length

  if isObject(iteratee)
    return not Object.keys(iteratee).length

###*
# Determine if iteratee is an Object
#
# @param   {*}       obj
# @returns {boolean}
###
isObject = (obj) ->
# http://stackoverflow.com/questions/8511281/check-if-a-value-is-an-object-in-javascript
  typeof obj is 'object' and not Array.isArray(obj) and obj isnt null

###*
# Delete key/value pairs from object
#
# @param   {Object}       obj
# @param   {String|Array} [keys] - Key list (Optional)
# @returns {Object}
###
omit = (obj, keys...) ->
  return obj if not keys.length

  keys = flattenDeep(keys)

  keys.forEach (key) ->
    delete obj[key] if obj.hasOwnProperty(key)
  obj

###*
# Pick key/value pairs from object
#
# @param   {Object}       obj
# @param   {String|Array} [keys] - Key list (Optional)
# @returns {Object}
###
pick = (obj, keys...) ->
  return {} if not keys.length

  keys = flattenDeep(keys)

  keys.reduce (collection, key) ->
    if obj.hasOwnProperty(key)
      collection[key] = obj[key]
    collection
  , {}

###*
# Remove falsey key/value pairs from object
#
# @param   {Object} obj
# @returns {Object}
###
pickByIdentity = (obj) ->
  Object.keys(obj).forEach (key) ->
    delete obj[key] if not obj[key]
  obj

###*
# Produces a duplicate-free version of the array
#
# @param   {Array}    arr
# @param   {*}                   - (Placeholder)
# @param   {function} [iteratee] - Transformation function
# @returns {Array}
###
uniq = (arr, ..., iteratee) ->
  if not Array.isArray(arr)
    arr = [arr]

  if typeof iteratee is 'function'
    arr = arr.map iteratee

  Array.from(new Set(arr))

###*
# Produces unique increasing numbers
#
# @param   {String}        [prefix] - Prefix to be set before the ID (Optional)
# @returns {String|Number}
###
uniqIteratee = 0
uniqueId = (prefix = '') ->
  i = uniqIteratee++
  prefix + i

#window.test = {
#  pick
#  omit
#}
module.exports = {
  escape
  flatten
  isEmpty
  isObject
  omit
  pick
  pickByIdentity
  uniq
  unescape
  uniqueId
}
