'use strict'

{ whichKeyAlias }    = require 'src/frontend/helper/search/common/m_keypress'
{ placeholderState } = require 'src/frontend/helper/search/helper/m_common'
SearchBarView = require 'src/frontend/search/search'

module.exports = class extends SearchBarView
  constructor: (opts = {}) ->
    @$body = $(document.body)

    Object.assign opts, {
      env: 'desktop'
      elements:
        form: 'form#ls-search-form'
        search: '#ls-search'
        location: '#ls-location'
        resultList: '#ls-search-results'
        submitBtn: '#ls-header-search-button'
      options:
        enableRecentSearches: true
        enableRecentLocations:  true
    }

    @$primaryNavTabs = $('.primary-nav-tab')

    @init(opts)

  bind: ->
    @$inputs.on
      keydown: (e) =>
        switch whichKeyAlias(e)
          when 'Enter'
            # submit but check if query matches autocomplete suggestion
            # to avoid manual google maps lookup
            if e.currentTarget.name is 'location'
              e.preventDefault()

              if ($match = @typeahead.$container.find('.typeahead-match-highlight')).length
                $match.trigger 'click'
              else
                @typeahead.hide()
                @$form.trigger 'submit'

          when 'Tab'
            $selectedItem = @$resultList.find('.typeahead-match')
            if $selectedItem.length
              # select the current highlighted item
              e.preventDefault()
              @selectAndTab $selectedItem, e.shiftKey
            else
              # blur input and remove autocomplete
              @typeahead.hide()

          when 'ArrowDown', 'Down'
            # navigate in autocomplete list
            @$resultList.find('a').eq(0).focus()
            @$resultList.find('.typeahead-match').removeClass 'typeahead-match-highlight'
            e.preventDefault()
        return

      focusin: (e) =>
        @$body.eventPause('pause')
        unless ($parent = $(e.currentTarget).parent()).hasClass('active-search')
          @$form.find('label, div').removeClass 'active-search'
          $parent.addClass('active-search')

      focusout: (e) =>
        @$body.eventPause('active')
        $this = $(e.currentTarget)
        $this.parent().removeClass('active-search')
        placeholderState.toggle($this)

        # black header: default to the deal search as the active-search
        if not $('html.ui-v3').length
          $searchLabel = @$search.parent()
          $locLabel = @$location.parent()

          if not $searchLabel.is('.active-search') and not $locLabel.is('.active-search')
            $searchLabel.addClass 'active-search'


    @$resultList.on 'keydown', 'a', (e) =>
      e.preventDefault()
      $widget = @$resultList
      $results = $widget.find('a')

      switch whichKeyAlias(e)
        when 'Enter'
          $el = $(e.currentTarget)
          if $el.hasClass 'current-location'
            $el.trigger('click')
          else
            @selectAndFill $el

        when 'ArrowUp', 'Up'
          $results.eq($results.index(e.currentTarget) - 1).focus()

        when 'ArrowDown', 'Down'
          $results.eq($results.index(e.currentTarget) + 1).focus()

        when 'Tab'
          @selectAndTab $(e.currentTarget), e.shiftKey

        else
          # otherwise close the dropdown and re-focus text input
          # NOTE: selector works only due to sibling relationship of input-resultlist
          @$resultList.prev('input').focus()

    ###
    # Global events
    ###
    @$body.on 'click', =>
      @typeahead.hide()

  lockSubmit: (lock = false) ->
    if lock
      @$submitBtn.attr('disabled', true).removeClass('icon-magnifying-glass').addClass('ls-loading-white')
      # pause events on UI elements
      @$location.eventPause('pause').addClass('search-hide-selection')
      @$primaryNavTabs.eventPause('pause')
    else
      @$submitBtn.attr('disabled', false).removeClass('ls-loading-white').addClass('icon-magnifying-glass')
      @$location.eventPause('active') # restore events on location input
      @$primaryNavTabs.eventPause('active') # restore events on primary nav
    return

  selectAndTab: ($trigger, shiftKey) ->
    source = @selectAndFillWithoutSubmit $trigger
    @$resultList.hide()
    if source is 'location' # From location search
      if shiftKey
        @$search.focus() # focus search input
      else
        @$submitBtn.focus() # focus submit button
    else # From deal search
      if not shiftKey
        @$location.focus() # focus location
    return
