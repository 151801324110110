'use strict'


###*
# Tests for matchMedia API support and if media query is met
#
# @param   {String}   [mediaQuery] - Media query string
# @param   {boolean}  [condition]  - Condition statement
# @returns {boolean}
###
matchMQ = (mediaQuery = Groupon.LS.mediaQueries.mMax, condition) ->
  if condition?
    return false unless condition
  matchMedia(mediaQuery).matches

addMQListener = (mediaQuery = Groupon.LS.mediaQueries.mMax, listener, condition) ->
  if condition?
    return false unless condition
  matchMedia(mediaQuery).addListener(listener)

module.exports = {
  matchMQ,
  addMQListener
}
