'use strict'

AriaMenuNavigation = require '../../../../../src/frontend/old/groupon_modules/ariaKeyNavigation'
stickyHeader = require '../sticky_header'

module.exports = class PrimaryNav extends AriaMenuNavigation

  constructor: (@primaryNav) ->
    @primaryNav ?= document.querySelectorAll('.primary-nav')
    return unless @primaryNav
    @$primaryNav = $(@primaryNav)
    @initialize()
    @bind()

  initialize: =>
    @primaryTabs = document.querySelectorAll('.primary-nav-tab')
    @$primaryTabs = $(@primaryTabs)
    @subnavs = document.querySelectorAll('.subnav-flyout')
    @$subnavs = $(@subnavs)
    @localHeightCalculated = false
    @timeoutID = 0
    @links = []
    @railSlide = document.getElementById('ls-rail-slide')

    setTimeout @moveActiveMarker, 100

    Array::forEach.call(@primaryTabs, (tab) =>
      tabID = tab.id
      subnav = document.getElementById("subnav-#{tabID}")
      if subnav
        # set aria attributes
        @initAriaAttributes(tab.querySelector('a'), subnav, subnav.querySelectorAll('a'))
      return
    )

  bind: ->
    if Groupon.PageFlags.isLegacyResponsive
      moveMarkerOnResize = =>
        @moveActiveMarker()

      $(window).on 'resize', _.debounce(moveMarkerOnResize, 50)

    # determine tabs with subnavs
    primaryTabsLinks = Array::filter.call(@primaryTabs, (primaryTab) ->
      link = primaryTab.querySelector('a')
      return link and document.getElementById("subnav-#{primaryTab.id}")
    )

    # bind keyboard handling to links
    Array::forEach.call(primaryTabsLinks, (links) => @ariaBindMenuTrigger(links))

    closeSubnav = =>
      # FIXME: this selector might not work in touch-device context
      unless $('.primary-nav:hover').length # prevent closing when mouse enters primary nav from the flyouts
        @closeSubnav()
        window.clearTimeout(@timeoutID)

      # LS-2925 resume sticky header
      stickyHeader.resume()

    startsSubnavTimer = => @hovTimeout = setTimeout closeSubnav, 50
    cancelSubnavTimer = => clearTimeout @hovTimeout

    startsActiveMarkerTimer = => @activeMarkerTimeout = setTimeout @moveActiveMarker, 50
    cancelActiveMarkerTimer = => clearTimeout @activeMarkerTimeout

    @$primaryNav.on
      mouseenter: ->
        cancelSubnavTimer()
        cancelActiveMarkerTimer()
      mouseleave: ->
        startsSubnavTimer()
        startsActiveMarkerTimer()

    @$primaryTabs.on
      mouseenter: (e) =>
        cancelActiveMarkerTimer()
        @moveActiveMarker e.currentTarget

    @$subnavs.on
      mouseenter: ->
        cancelSubnavTimer()
        cancelActiveMarkerTimer()
      mouseleave: ->
        startsSubnavTimer()
        startsActiveMarkerTimer()


    $primaryTabsLinks = $(primaryTabsLinks)

    if Groupon.LS.hasTouch
      Array::forEach.call(primaryTabsLinks, (tabLink) -> tabLink.classList.add('lstouch'))

      # bind event to tab's <a>
      $primaryTabsLinks.on 'lstouch', (e) =>
        parent = e.currentTarget.parentNode

        # toggle subnav visibility
        if $('#subnav-' + parent.id).is(':visible')
          @closeVisibleSubnav()
        else
          # close other header menus
          $('html').trigger 'tap'
          # open subnav
          $(parent).trigger 'touch', e

        return false # required to prevent propagation to the link target

      # close visible subnav when tapping any element but the flyouts
      $('html').on 'tap', (e) =>
        # test for subnav to be open AND if the event hasn't be triggered
        # within the channel navigation primary nav and flyouts
        if $('.subnav').height() and $(e.target).closest('.primary-nav, .subnav-flyout', '#ls-channel-nav').length == 0
          @closeVisibleSubnav()

    # bind event to tab's <li> to open subnav
    @$primaryTabs.on 'mouseenter touch', (e) =>
      e.stopPropagation()
      @handleOpenSubnav(e)

    return

  closeSubnav: ->
    # reset tabindex
    Array::forEach.call(@links, (link) ->
      link.setAttribute('tabindex', '-1')
    )

    Array::forEach.call(@subnavs, (subnav) ->
      subnav.classList.add('ls-hide')
      subnav.setAttribute('aria-hidden', 'true')
    )
    return

  # used by keyboardNavigation
  closeVisibleSubnav: (activeTab) ->
    @closeSubnav()
    if activeTab
      activeTab.focus()
    return

  handleOpenSubnav: (e) =>
    window.clearTimeout(@timeoutID)

    anyVisible = Array::some.call(@subnavs, (subnav) ->
      { width, height } = subnav.getBoundingClientRect()
      return width * height
    )
    flyoutDelay = if anyVisible then 0 else 300

    tab = e.currentTarget
    tab = tab.parentNode if tab.nodeName is 'A'
    eventType = e.type
    flyout = document.getElementById("subnav-#{tab.id}")

    if not flyout
      @closeSubnav()

    # When API call in layout-service fails, we will not generate flyout for a tab,
    # and the default static fallback is designed to show no subnav.
    if flyout
      stickyHeader.pause() # LS-2925 pause sticky header
      document.querySelector('.typeahead-response').style.display = 'none'

      openSubnav = => @openSubnav tab, flyout, eventType

      if flyoutDelay
        @timeoutID = setTimeout openSubnav, flyoutDelay
      else
        openSubnav()

  openSubnav: (tab, flyout, eventType) =>
    @closeSubnav()
    activeTab = tab.querySelector('a')
    flyout.classList.remove('ls-hide')
    flyout.setAttribute('aria-hidden', 'false')

    # handle keyboard navigation
    if eventType is 'keydown'
      @links = links = flyout.querySelectorAll('a')

      # init attributes and events per subnav
      @initAriaMenuNavigation(activeTab, flyout, links)

      flyout.setAttribute('aria-hidden', 'false')

      Array::forEach.call(links, (link) ->
        link.setAttribute('tabindex', '-1')
      )
      # set focus on first link
      firstLink = links[0]
      firstLink.focus()
      firstLink.setAttribute('tabindex', '0')


  moveActiveMarker: (target) =>
    link = target or document.querySelector('.ls-current-app, .default')

    if link and link.nodeName is 'LI'
      link = link.querySelector 'a'

    if link
      { left, width } = link.getBoundingClientRect()
      @railSlide.classList.remove('ls-hide')
      @railSlide.setAttribute('style', "left:#{left-10}px;width:#{width+20}px")
    else
      @railSlide.classList.add('ls-hide')
    return

  # override AriaMenuNavigation functions
  ariaBindMenuTrigger: (trigger) ->
    handler = (e) =>
      { SPACE, DOWN } = Groupon.Keymap
      if e.keyCode in [SPACE, DOWN]
        @handleOpenSubnav(e)

    trigger.removeEventListener('keydown', handler, { passive: true})
    trigger.addEventListener('keydown', handler, { passive: true })
    return

  ariaCloseMenu: (trigger) => @closeVisibleSubnav(trigger)

# Keep for Careers ITA
# TODO: move the code into careers app so we can resolve this dependency
