'use strict';

const { escape } = require('./m_underscore_replacements.coffee');

const makeTag = (name, attr = {}, content = '') => {
  if (typeof attr === 'string') {
    [attr, content] = Array.from([{}, attr]);
  }
  let html = `<${name}`;
  const tail = `</${name}>`;
  for (let key in attr) {
    let val = attr[key];
    key = escape(key);
    val = escape(String(val));
    html += ` ${key}="${val}"`;
  }
  return html + '>' + content + tail;
};

makeTag['a'] = (...args) => makeTag('a', ...args);

// Groupon.Utils.makeTag
module.exports = makeTag;
