'use strict'

I18N_ERROR_GENERIC             = 'generic'
I18N_ERROR_BAD_LOCATION        = 'bad_location'
I18N_ERROR_BAD_LOCATION_ENTRY  = 'bad_location_entry'
I18N_ERROR_AVAILABILITY        = 'availability'

{ escape } = require '../../general/m_underscore_replacements'
{ moveAutocomplete } = require '../helper/m_typeahead_helper'
{ getTranslations } = require '../helper/m_translations'

# Groupon.Modules.SearchBar.LocationError:
module.exports = class LocationError
  constructor: (opts) ->
    {
      $form: @$form
      env: @env
      $resultList: @$resultList
    } = opts
    @hasError = false
    @classes = 'ls-error'
    @$locationWrapper = @$form.find('.location-search, .ls-location-wrap')

  errorTemplate: (query, message) ->
    query = escape query
    message = escape message
    """
      <ul><li data-bhw='SearchBarError' class='ls-error-dialog icon-circle-alert'>
        <span data-bhc='location-not-found:#{ query }'>#{ message }</span>
      </li></ul>
    """

  displayError: (message, query) =>

    message ||= @translateError(I18N_ERROR_GENERIC)
    $errorDialog = $(@errorTemplate query, message)

    moveAutocomplete(@$resultList, 'location', @env)

    @$locationWrapper.addClass(@classes)
    @$resultList
      .html($errorDialog)
      .show()
    @$locationWrapper.find('input').one 'input.locationError', @removeError

    @hasError = true
    switch @env
      when 'desktop'
        event = 'mousedown'
      when 'mobile'
        event = 'touchend'

    $(window).one event, @removeError

  removeError: =>
    if @hasError
      @$resultList.hide().find('.ls-error-dialog').remove()
      @$locationWrapper.removeClass(@classes)
      @hasError = false
    return

  displayBadLocationError: (query) ->
    if @expUX
      txtKey = I18N_ERROR_BAD_LOCATION_ENTRY
    else
      txtKey = I18N_ERROR_BAD_LOCATION
    message = @translateError txtKey
    @displayError message, query

  displayAvailabilityError: (query) ->
    message = @translateError I18N_ERROR_AVAILABILITY
    @displayError message, query

  translateError: (key) ->
    getTranslations('error')[key]
