'use strict'

KeywordBar                = require '../helper/search/view/m_keyword_bar'
Location                  = require '../helper/search/helper/m_location_helper'
LocationBar               = require '../helper/search/view/m_location_bar'
LocationError             = require '../helper/search/view/m_error'
Typeahead                 = require '../helper/search/view/m_typeahead'
Geocoder                  = require '../helper/search/common/m_geocoder'
redirectToUrl             = require '../helper/general/m_setWindowLocation'
trackEvent                = require '../helper/general/m_trackEvent'

{ isBlank }               = require '../old/common/underscore_mixins'
{ pick, omit, unescape }  = require '../helper/general/m_underscore_replacements'
{ breakDownAddress
, getGeoSearchOverrideUrl
, getLocationForSearch
, getLocationOverrideUrl
, getSearchOverrideUrl
, getSearchUrl
, GEO_DATA
, removeGeolocationData
, storeGeolocationData
, storeLocationData
, getSearchUrlForCategoryItem
}                         = require '../helper/search/helper/m_searchbar_helper'
{
  saveRecentDeal
, fixStoredRecentLocation
} = require '../helper/search/helper/m_typeahead_helper.coffee'
{ detectCurrentLocation } = require '../helper/search/helper/m_detectCurrentLocation'
{ placeholderState }      = require '../helper/search/helper/m_common'

TEN_YEARS_IN_MS = 1000 * 60 * 60 * 24 * 365 * 10
THIRTY_MINUTES_IN_MS = 1000 * 60 * 30
locationHasChanged = undefined

module.exports = class
  keyword = translations = undefined

  constructor: ->
  init: (opts) ->
    {
      elements
    , env: @env
    , options: @options
    } = opts
    @selectedResult = {}

    # init selectors
#    {
#      form
#    , location
#    , search
#    , resultList
#    , submitBtn
#    , overlay
#    } = elements

    for name, selector of elements
      @['$' + name] = $(selector)

    @$inputs = @$search?.add(@$location)

    @setCommonExperiments() # set the experiments for both desktop and mobile
    @setExperiments?() # call if it exists
    @render(@options)

    @bindFormSubmit()
    @bindDefault()
    @bind?()

    {
      country: @country
    } = I18n
    if @intAddAutocomplete is undefined
      @intAddAutocomplete = false
      Finch.experiment 'LS-4636-InternationalAddressAutocomplete', {
        Treatment: => @intAddAutocomplete = true
      }
    @useLocationPlacesAPI = @country is 'US' or @intAddAutocomplete # LS-4025
    @typeahead?.fetchWithPlacesApi = @useLocationPlacesAPI
    translations = opts.translations or Groupon.LS.translations or {} # fallback for client tests

    @geolocationData = Location.getGeolocationData()

    fixStoredRecentLocation()

    return

  setCommonExperiments: ->

  ###
  # Bind form submit handler
  ###
  bindFormSubmit: ->
    @$form.on 'submit', (e) => @onFormSubmit(e)

  bindDefault: ->
    ###
    # Input events
    ###
    @$inputs.on 'input focusin', (e) =>
      @selectedResult = {}
      el = e.currentTarget
      placeholderState.toggle($(el))
      e.preventDefault()
      # the e.currentTarget might change to form from input because of event propagation,
      # because it could be a 500ms delay after we use _.throttle
      @typeahead.buildAutocompleteDelayed(_.clone(e), el.name)

    ###
    # Typeahead events
    ###
    @$resultList.on 'click', 'a', (e) =>
      e.preventDefault() # prevent focus on location input
      @selectAndFill($(e.currentTarget))

    # test if inputs have been filled by ITA
    $(window).one 'load', =>
      @$form.find('input').each ->
        placeholderState.toggle($(this))

  # Fill with the current highlighted item
  # From deals
  #   update selected value to deals search input
  #   return undefined
  # From location
  #   update selected value to location input
  #   return 'autocomplete'
  selectAndFillWithoutSubmit: ($trigger) ->
    $widget = @$resultList
    # remove matched class so the correct lat/lng will be picked in searchbar onSubmit()
    $widget.find('.typeahead-match').removeClass('typeahead-match')

    if $widget.hasClass 'typeahead-location'
      source = 'location'
      @_updateValueAndLatLng $trigger, @$location
    else
      source = 'deal'
      @_updateValue $trigger, @$search
    source

  ###*
  # Autocomplete link handler
  # Updates deal or location input
  #
  # @param {Object} e - jQuery event
  ###
  selectAndFill: ($trigger) ->
    trigger = $trigger[0]
    if !(trigger.closest('.typeahead-response')?.classList.contains('typeahead-location'))
      { value, subvalue, permalink, level, channel } = trigger.dataset
      Object.assign(@selectedResult, { value, subValue: subvalue, permalink, level, channel })
    @selectAndFillWithoutSubmit $trigger
    @$form.trigger 'submit'
    return

  render: (opts) ->
    {options, experiments, flags} = Groupon.LS
    options ?= {}
    options = Object.assign {}, options, experiments, flags, opts, {
      fetchWithPlacesApi: @useLocationPlacesAPI
    }

    @typeahead = new Typeahead {
      env: @env
      resultList: @$resultList
      endpoints:
        search:   Groupon.endpoints?.deals
        location: Groupon.endpoints?.locations
      options
      experiments
    }

    if @$search?
      @keywordBar = new KeywordBar {
        $form: @$form
        env: @env
      }

    if @$location?
      @locationError = new LocationError {
        $form: @$form
        env: @env
        $resultList: @$resultList
        enableBetterUx: options.enableBetterUx
      }

      @locationBar = new LocationBar {
        $form: @$form
        env: @env
      }

    @setSearchbarFocusTracking()

  onFormSubmit: (e) =>
    e.preventDefault()

    state = @locationState
    { data, state } = @_gatherData(state)
    return unless data?

    @lockSubmit?(true)

    # LS-750 location and query override
    overrideUrl = getGeoSearchOverrideUrl(data.query, data.address)
    if overrideUrl?
      return redirectToUrl overrideUrl

    locationHasChanged = true
    switch state
      when 'geolocate'
        data.currentLocation = true
        locationHasChanged = false
        fn = @_searchWithGeolocation

      when 'autocomplete'
        fn = @_searchWithAutocomplete
        Location.removeGeolocationData('current_location')

        # LS-3106 use places api for autocomplete
        if @useLocationPlacesAPI and data.place_id
          fn = @_searchWithPlaces

      when 'manual'
        fn = @_searchWithGmaps
        Location.removeGeolocationData('current_location')

      else
        locationHasChanged = false
        fn = @_redirectTo

    (fn.bind(this))(data)

  setSearchbarFocusTracking: =>
    @$form.on 'focusin.searchbar', (e) =>
      # is event target a searchbar form element
      if e.target and $(e.currentTarget).find(e.target).length
        trackEvent 'searchbar-focusin'
        @$form.off 'focusin.searchbar'
        @$form.on 'focusout.searchbar', (event) =>
          # is event relatedTarget not in searchbar
          # NOTE: event.target can not be used. It is still a searchbar element
          if not $(event.currentTarget).find(event.relatedTarget).length
            trackEvent 'searchbar-focusout'
            @$form.off 'focusout.searchbar'
            @setSearchbarFocusTracking()

  ###
  # ------------------
  # PRIVATE NON-PUBLIC
  # ------------------
  ###

  ###*
  # Set location data to location input from autocomplete item
  #
  # @param {Object} $trigger - jQuery object of autocomplete item
  ###
  _updateValueAndLatLng: ($source, $target) ->
    { value, lat, lng, division, place_id } = $source.data()

    if $source.hasClass 'current-location' # select current location
      @locationState = 'geolocate'
      value = translations.search.nearme
      data = {current_location: true, manual: false, value}
    else
      @locationState = 'autocomplete'
      # Make sure it has place_id if we use location api
      data = if @useLocationPlacesAPI and place_id
        {place_id, 'manual': false, value}
      else
        {lat: lat.toString(), lng: lng.toString(), division, 'manual': false, value}

    $target.val(value).data data
    return

  ###*
  # Write deal data to deal input from autocomplete item
  #
  # @param {Object} $source - jQuery object of autocomplete item
  ###
  _updateValue: ($source = {}, $target = {}) ->
    placeholderState.set($target, 'hide')
    $target.val(unescape(String($source.data('value'))))
    return

  _getQuery: =>
    maxChars = @$search.attr('maxlength') or 50
    keyword = @keywordBar?.val()?.trim().slice(0, maxChars)
    keyword unless isBlank(keyword)

  _getQueryWithHref: =>
    maxChars = @$search.attr('maxlength') or 50
    { subValue = '', value = '', level = -1, permalink = '', channel = '' } = @selectedResult or {}
    keyword = {
      value: (value || @keywordBar?.val() || '').trim().slice(0, maxChars)
      subValue: subValue.trim()
      level: level
      permalink: permalink
      channel: channel

    }
    return keyword if keyword.value and  not isBlank(keyword.value)

  # Pick the location data
  _pickLocationData: (data, withGEO = false) ->
    paths = ['lat', 'lng', 'closestDivision', 'division', 'place_id']
    paths = paths.concat GEO_DATA if withGEO
    pick data, paths

  _gatherData: (state) =>
    geolocationData = @geolocationData?.current_location
    dealSearch = @_getQueryWithHref()
    locSearch = @locationBar.val()
    data = null
    if not dealSearch and not locSearch
      return { state, data }

    # save the keyword in local storage
    saveRecentDeal(dealSearch) if dealSearch?

    # test if autocomplete has a match

    if (locationMatch = $('.typeahead-location .typeahead-match')).length
      state ?= 'autocomplete'
      data = @_pickLocationData locationMatch.data()
    else
      locData = @$location.data()
      # handle case where first a manual change on location takes place
      # and then a change in deal input/autocomplete with following submit
      # the check for undefined is important so in case of e.g geolocation the
      # state is not overwritten
      if locData.manual and state is undefined then state = 'manual'
      # If the value was set from autocomplete or geolocate
      # the value of input should be equal with the value from data-value attribute
      # otherwise, it was changed by user manually
      if locData.value and locData.value isnt locSearch
        state = 'manual'

      switch state
        when 'autocomplete', 'manual'
          data = @_pickLocationData locData
        else
          data = @_pickLocationData locData, true
          if Date.now() - geolocationData?.timeStamp > THIRTY_MINUTES_IN_MS
            state = 'geolocate'

    if not geolocationData or not data.closestDivision
      data.closestDivision = Groupon.division?.id
    if keyword.subValue then (query = keyword.subValue) else (query = keyword.value)
    {
      data: Object.assign data, { address: locSearch, query }
      state
    }

  _cleanGeolocationTraces: =>
    Location.removeGeolocationData('current_location')
    Location.setGeolocationData('geoLocationGranted', false)

    if translations.search.currentlocation is @$location.val() and (data = Groupon.division)?
      {lat, lng, name} = data
      options = {
        address: name
        lat
        lng
        geocoderSuccess: ({ locationData }) =>
          @$location.data(locationData).val(locationData.friendlyName)
          Location.setSearchLocCookie(locationData)
        geocoderError: ->
      }

      @_searchWithGmaps options

  ###*
  # HTML5 geolocation handler to get latitude and longitude
  # and perform geocoder lookup via Google Maps
  #
  # @param {Object} data
  ###
  _searchWithGeolocation: (data) =>
    unless data?
      query = @_getQuery()
      data =
        address: translations.search.nearme
        query: query

    if @env is 'desktop'
      $('.typeahead-response.location').hide()

    geolocationSuccess = ({lat, lng}) =>
      @$location.val data.address
      data = Object.assign data, {lat, lng, current_location: true}
      trackEvent 'location-bar-geolocation-success', {lat, lng}
      # browser prompt geolocation succeeded and was accepted
      Location.setGeolocationData('geoLocationGranted', true)
      @_searchWithAutocomplete data

    geoLocationError = (err) =>
      @_cleanGeolocationTraces()
      trackEvent 'location-bar-geolocation-error', err # pass PositionError object (https://developer.mozilla.org/en-US/docs/Web/API/PositionError)
      @locationError.displayAvailabilityError('current-location')
      @lockSubmit?(false)

    detectCurrentLocation(geolocationSuccess, geoLocationError)

  ###*
  # Google Maps handler for geocoder lookup
  #
  # @param {Object} [options] - Location data
  ###
  _searchWithGmaps: (options = {}) =>
    {address, current_location, lat, lng, geocoderSuccess, geocoderError} = options
    options = {
      address
      location: {
        lat
        lng
      }
      current_location
      country: @country
      geocoderSuccess
      geocoderError
    }
    unless geocoderSuccess or geocoderError
      options = Object.assign {}, options, {
        geocoderSuccess: @_geocoderSuccess.bind(this)
        geocoderError: @_geocoderError.bind(this)
      }

    unless @geocoder
      @geocoder = new Geocoder()
    @geocoder.geocode(options)

  ###*
  # Location autocomplete handler
  # Uses data to directly redirect or do closestDivision lookup
  #
  # @param {Object} data - Location data
  ###
  _searchWithAutocomplete: (data) =>
    {address, query} = data
    trackEvent 'location-bar-autocomplete-successful-match', {query, address}
    data = breakDownAddress data  if @country is 'US'

    # skip closestDivision lookup when division name is known
    if data.division
      # flat the geocoderData sub-object
      locationData = Object.assign data, data.geocoderData
      delete locationData.geocoderData
      data.closestDivision = data.division # using division as closestDivision
      return @_geocoderSuccess({address: data.address, locationData})

    data = Object.assign({}, data, {
      geocoderSuccess: @_geocoderSuccess.bind(this)
      geocoderError: @_geocoderError.bind(this)
    })
    new Geocoder().fetchClosestDivision(data)
    return

  ###*
  # LS-3106 search with Places API proxy and getClosestDivision
  #
  # @param {Object} placesData
  ###
  _searchWithPlaces: (placesData) =>
    {address, query, place_id} = placesData
    trackEvent 'location-bar-places-successful-match', placesData
    error = (err) =>
      @_geocoderError({address, status: 'UNAVAILABLE', data: err})

    ajaxConfig = {
      url: [ Groupon.endpoints?.places_details, 'v1' ].join '/'
      data: {  place_id }
      headers: { 'x-country': I18n.country }
      success: ({data, err}) =>
        return error(err) if err
        (@_geocoderSuccess.bind(this))({
          query, address,
          locationData: Object.assign(data, {
            locationId: place_id,
            fullAddress: address or data.fullAddress # overwrite it with the autocomplete address LS-3269 because we use fullAddress to display the location
          }),
          current_location: false
        })
      error: error
    }

    $.ajax ajaxConfig
    return

  _geocoderSuccess: ({address, query, locationData, current_location}) =>
    Location.setELLCookie(locationData.lat + ',' + locationData.lng)
    query ?= keyword
    if locationData.fullAddress?
      address = locationData.fullAddress
    trackyEventName = 'location-bar-geocode-search-success'

    if current_location
      # overwriting detailed user address
      address = translations.search.nearme
      trackyEventName = 'location-bar-geolocation-search-success'

      currentLocationData = pick locationData, 'closestDivision', 'division', 'address'
      currentLocationData = Object.assign currentLocationData, { timeStamp: new Date().getTime()}
      storeGeolocationData(current_location, currentLocationData)
      locationData.currentLocation = true
    else
      removeGeolocationData()
    locationData.address = locationData.friendlyName = address
    Location.setSearchLocCookie(locationData)
    hasLocCookie = true

    trackEvent trackyEventName, {address, locationData, query}

    if (data = locationData or getLocationForSearch())
      data = omit data, ['fullAddress', 'friendlyName', 'types']
      data = Object.assign data, { query }

      @_redirectTo(data, hasLocCookie)

  _geocoderError: ({address, status, data}) =>
    @lockSubmit?(false)
    return if status is 'ABORT'

    if status is 'BAD_LOCATION'
      @locationError.displayBadLocationError(address)
    else if status is 'UNAVAILABLE'
      @locationError.displayAvailabilityError(address)

    trackEvent 'location-bar-geocode-search-error', {address, data}

  _redirectTo: (data, hasLocCookie) =>
    {closestDivision, division} = data
    unless closestDivision? or division?
      @lockSubmit?(false)
      return

    if closestDivision and not division
      data.division = division = closestDivision


    # save the location keyword in local storage
    if locationHasChanged
      storeLocationData data
      # set division cookie
      Cookie.set 'division', division, { maxAge: TEN_YEARS_IN_MS, consentClass: 'essential'}

    # emit tracky xhr
    { address, query } = data
    searchEvent = if query
      if hasLocCookie then 'deal-search-with-loc-cookie' else 'deal-search-with-no-loc-cookie'
    else
      'location-search'
    trackEvent searchEvent, { address, query }

    newUrl = getLocationOverrideUrl(data) or
      getSearchOverrideUrl(division, data, @env is 'mobile') or
      getSearchUrlForCategoryItem(@selectedResult, division, omit(data, 'closestDivision')) or
      getSearchUrl(division, omit(data, 'closestDivision'))

    redirectToUrl newUrl
    return
