'use strict'

# LS-1324/LS-1754: verify cookies
module.exports = ->
  setTimeout (->

    src = '/verify_cookies'
    # test if verification was already done in current session
    return if window.sessionStorage?.getItem('verifiedCookies')
    el = document.createElement('script')
    el.id = 'verify_cookies'
    el.async = true
    document.body.appendChild(el).src = src
    try
      sessionStorage?.setItem('verifiedCookies', true)
), 0
