'use strict'
loadAsyncJS = require('../../../../../src/frontend/helper/general/m_loadAsyncJS')
{ getGoogleConsentsPayload, getLegalVersionsFromDOM } = require('./helpers')
googleId = 'google-jssdk'
googleUrl = '//apis.google.com/js/api:client.js'

# https://developers.google.com/identity/sign-in/web/listeners
Google =

  init: (signinFlyout) ->
    return if not @googleBtn = document.getElementById('ls-btn-google')
    @signinFlyout = signinFlyout

    cb = =>
      gapi.load('auth2', @initSigninV2.bind(this))

    if document.getElementById googleId
      cb()
    else
      loadAsyncJS googleUrl, googleId, cb

  initSigninV2: ->
    auth2 = gapi.auth2.init({
      client_id: Groupon.LS.data.googleClientId
    })
    auth2.attachClickHandler(@googleBtn, {}, @successHandler.bind(this), @failureHandler.bind(this))

  successHandler: (googleUser) ->
    id_token = googleUser.getAuthResponse().id_token
    url = location.origin + '/google_auth_callback' + location.search
    _csrf = document.querySelector('input[name=_csrf]').value

    req = {
      credentials: 'same-origin',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      method: 'POST',
      body: "_csrf=#{_csrf}&id_token=#{id_token}&remember_me=1"
    }

    if Groupon.LS.experiments?.showGDPRSigninFlyout
      { termsVersion, privacyVersion } = getLegalVersionsFromDOM()
      legalConsents = encodeURIComponent(JSON.stringify(getGoogleConsentsPayload(privacyVersion, termsVersion)))
      res.body += "&legalConsents=#{legalConsents}"

    fetch(url, req)
    .then (res) ->
      if not res.ok
        throw new Error(res.statusText)
      return res.json()
    .then (resData) ->
      redirectUrl = resData?.redirectTo || window.location.href
      Groupon.setWindowLocation redirectUrl
    .catch (error) ->
      Groupon.Utils.trackEvent('google-signin-failure', { error: error })

  failureHandler: (error) ->
    Groupon.Utils.trackEvent('google-signin-popup-closed', { error: error })

module.exports = Google
