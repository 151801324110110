'use strict'

{ escape }         = require '../../general/m_underscore_replacements'

{ fixLatOrLng } = require './m_location_helper' # for unknown reason this doesn't work / the function fails with unknown constructor error

{ setTranslations, getTranslations } = require './m_translations'

###*
# Generate basic bloodhound tag name
#
# @param   {String} [type]     - Name of the api / collection name
# @param   {String} attributes
# @returns {String} bhw        - Base bloodhound tag
###
createBloodhoundTag = (type = '') ->
  type.split('|').slice(-1).join('').toLowerCase()

###*
# Returns list of recent searches queries retrieved from localStorage
#
# param    {String} - Input type
# @returns {Array}  - list
###
getRecentSearches = (type = 'deal') ->
  list = []
  try
    switch type
      when 'deal'
        list = JSON.parse(window.localStorage?.getItem('LS_recentSearches_Desktop') or '[]')

      when 'location'
        list = JSON.parse(window.localStorage?.getItem('LS_recentLocations') or '[]')

  list


###*
# Move typeahead container into its context.
# This is only needed for desktop
#
# @param {Object} $container - Selector for typeahead wrapper
# @param {String} ctx        - Typeahead context
# @param {String} env        - Environment
###
moveAutocomplete = ($container, ctx, env) ->
  if env is 'desktop'
    switch ctx
      when 'search'
        selector = '.deal-search'
      when 'location'
        selector = '.location-search'
    if selector and not $container.hasClass 'typeahead-modal'
      $(selector).append($container)

  return


###*
# Convert, prepend and save term to recent searches list
#
# @param {String} term   - Deal query entered by user
# @param {String} [type] - input type (Optional)
###
removeRecentSearch = (term, type = 'deal') ->
  type = 'deal' if type is 'search'

  keywords = getRecentSearches(type) or []
  term = escape(term).trim().toLowerCase()
  switch type
    when 'deal'
      index = keywords.findIndex (n) ->
        if n.value
          return escape(n.value).trim().toLowerCase() is term
        false
    when 'location'
      index = keywords.findIndex (n) ->
        if n.address
          return escape(n.address).trim().toLowerCase() is term
        false

  if index > -1
    keywords.splice(index, 1)

  setRecentSearches keywords, type
  return

###*
# Helper function to remove stored searches
#
# @param {String} type - Input type
###
removeRecentSearches = (type = 'deal') ->
  type = 'deal' if type is 'search'
  switch type
    when 'deal'
      window.localStorage.removeItem 'LS_recentSearches_Desktop'
    when 'location'
      window.localStorage.removeItem 'LS_recentLocations'
  return

###*
# Saves recent searches array as string in localStorage
#
# @param {Array}  keywords - List of keywords searched by user
# @param {String} type     - input type
###
setRecentSearches = (keywords, type) ->

  switch type
    when 'deal'
      key = 'LS_recentSearches_Desktop'
    when 'location'
      key = 'LS_recentLocations'

  try
    window.localStorage?.setItem(key, JSON.stringify(keywords))


###*
# Convert, prepend and save term to recent searches list
#
# @param {String} term - Deal query entered by user
# @param {String} type - input type (Optional)
# @param {String} env  - Environment. Controls amount of items (Optional)
###
saveRecentLocation = (term) ->
  keywords = getRecentSearches('location') or []
  index = keywords.findIndex (n) -> n.address is term.address

  if index > -1
    keywords.splice(0, 0, keywords.splice(index, 1)[0]) # move term to the beginning
  else
    keywords.unshift(term)  # prepend term

  setRecentSearches keywords.slice(0, 10), 'location'
  return

saveRecentDeal = (term) ->
  keywords = getRecentSearches('deal') or []
  filteredKeywords = keywords
    .filter (n) ->
      n.subValue != term.subValue or n.value != term.value
  filteredKeywords.unshift(term)
  setRecentSearches filteredKeywords.slice(0, 10), 'deal'
  return

fixStoredRecentLocation = ->
  requestIdleCallback ->
    type = 'location'
    locations = getRecentSearches(type)
    locations = locations.map (location) ->
      { lat, lng } = location
      Object.assign {}, location, {lat: fixLatOrLng(lat), lng: fixLatOrLng(lng)}

    setRecentSearches locations, type


module.exports = {
  createBloodhoundTag
  fixStoredRecentLocation
  getRecentSearches
  moveAutocomplete
  removeRecentSearch
  removeRecentSearches
  saveRecentDeal
  saveRecentLocation
  setRecentSearches
}
