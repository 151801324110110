'use strict'

require './helper/general/m_googleTagManager'

require './old/common/underscore_mixins'
window.Hogan = require '../../node_modules/hogan.js/lib/hogan'
require './old/groupon_modules/groupon'
SearchBar = require '../../modules/gig_body/header/search_bar/frontend/desktop_searchbar'
require './old/groupon_modules/performance'

require './lazyload/lazyLoadImages'
LSTouch = require '../../modules/gig_body/header/frontend/views/ls_touch'
PNHeaderV2 = require '../../modules/gig_body/header/frontend/views/pn_header_v2'
stickyHeader = require '../../modules/gig_body/header/frontend/sticky_header'
require '../../modules/gig_body/components/back2top/frontend/main'
{ init: initDoubleClick } = require '../../modules/doubleclick/frontend/doubleclick'
verifyUserCookies = require '../../src/frontend/helper/general/m_verifyUserCookies'
{User} = require '../../modules/gig_body/header/usernav/frontend/setup_user'
uncommentThirdPartyAssets = require './old/groupon_modules/uncommentThirdPartyAssets'
gcxFlyout = require '../../modules/gig_body/header/sub_nav/frontend/init_flyout'
{ incognitoTest } = require './helper/incognito'
{ initCllTimer } = require './helper/search/helper/m_detectCurrentLocation'
{ setELLFromSearchLoc } = require './helper/search/helper/m_location_helper'
{ getCardatronCards } = require '../../modules/gig_body/header/sub_nav/components/cards/frontend/getCardatronCards'
{ gdprCookieBanner } = require '../../modules/cookie_banner_gdpr/frontend/main'
incognitoTest('desktop')
loadAsyncJS = require './helper/general/m_loadAsyncJS'
{ parsed, loaded } = require './core/browser_events'
setELLFromSearchLoc() # set ELL from searchLoc cookie

parsed.then ->
  Groupon.Utils.lazyLoadImages()
  gdprCookieBanner()

  SearchBar.init()

  # When < 600px (mobile), change the viewport meta tag to disable user scaling.
  # This prevents unnecessary zooming when focusing on inputs and is an alternative to
  # setting the font size of the inputs to 16px (which Apple recommends for iOS Safari).
  mMax = Groupon.LS.mediaQueries.mMax
  if Groupon.LS.hasTouch and matchMedia?(mMax).matches
    $(document.head).find('meta[name=viewport]').attr('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0')
  Groupon.Utils.lazyLoadImages()
  LSTouch.init()
  if (header = document.querySelector('.header-v2'))
    new PNHeaderV2 header
  $('body').removeClass 'no-js'
  OptimizeSuite.initBloodhound() unless Optimize.delayBloodhound

  # Add handler for back link on responsive header
  $('a.t-back-link').not('[href]').on 'click', ->
    history.back()

  $("#subscribe_modal_container")?.trigger 'launchSubsUIModal'

  # Kick off flyout handlers (no-op when element doesn't exist)
  gcxFlyout.init()

loaded.then ->
  User.init()
  setTimeout ( ->
    initCllTimer()

    Groupon.Utils.lazyLoadImages({threshold: 200}, '.lazy-wl')
    new Groupon.Widgets.BackToTopButton()

    if $('.header-v2').length
      # LS-2925 init after window load, so the page has already scrolled to it's cached position.
      stickyHeader.init()

    verifyUserCookies()

    uncommentThirdPartyAssets()

    getCardatronCards()

    if Groupon?.LS?.data?.appName is 'deal'
      Finch?.experiment("LS-3080-AADealPageBucketing", {
        Control: ->
          # Nothing
        Treatment: ->
          # Nothing
      })

    if Optimize.delayBloodhound
      setTimeout (->
        OptimizeSuite.initBloodhound()
      ), 0

    initDoubleClick()
  ), 0

  return
