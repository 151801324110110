import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import api from 'app/common/middleware/api';
import reducers from 'app/desktop/reducers';

const configureStore = initialState =>
  createStore(
    reducers,
    initialState,
    composeWithDevTools(applyMiddleware(thunk, api))
  );

export default configureStore;
