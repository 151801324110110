'use strict'

camel2Dash = (name) ->
  name.replace /([A-Z])/g, (match, capture1) -> '-' + capture1.toLowerCase()

mapOptions = (opts) ->
  opts.support = {
    srcset: 'srcset' of document.createElement('img')
    imgset: CSS?.supports('background-image', '-webkit-image-set(url(a.jpg) 1x)')
  }
  opts.class_loaded = 'lazy-loaded'

  {
    selector
    callback_enter: cb_enter
    callback_load: cb_load
  } = opts

  if selector
    opts.elements_selector = selector

  opts.callback_enter = (el) ->
    prepareSources(el, opts)
    cb_enter?()

  opts.callback_load = (el) ->
    cleanSources(el)
    cb_load?()

  opts

mapToSrcset = (el, name, { support }) ->
  srcset = []

  dataSet = el.dataset
  camelName = 'data-' + camel2Dash(name)

  if src = dataSet[name]
    srcset.push src
    el.removeAttribute(camelName)

  if src = dataSet[name + '-2x']
    srcset.push "#{src} 2x"
    el.removeAttribute(camelName + '-2x')
  if src = dataSet[name + '-3x']
    srcset.push "#{src} 3x"
    el.removeAttribute(camelName + '-3x')

  if srcset.length
    key = 'src'
    joiner = ''
    if support.srcset and srcset.length > 1
      key = 'srcset'
      joiner = ' ,'
      urls  = srcset.join(joiner)
    else
      urls = srcset.slice(-1)[0].split(' ')[0]

    el.dataset[key] = urls

mapUrls = (urls) ->
  urls.map(({imageUrl, resolution}) -> "url(#{imageUrl}) #{resolution}")

handleNormalImages = (el, opts = {}) ->
  mapToSrcset(el, 'original', opts)

handleProgressiveLoad = (el, opts = {}) ->
  mapToSrcset(el, 'highQuality', Object.assign({}, opts, { useSrcset: true }))

handleBackgroundImages = (el, opts = {}) ->
  if el.nodeName isnt 'IMG'
    { src, srcset } = el.dataset
    return unless src or srcset

    if srcset
      urls = srcset.split(',').map((url) ->
        [imageUrl, resolution] = url.split(' ')
        if not resolution?.trim()
          resolution = '1x'
        {
          imageUrl
          resolution
        }
      )
      highRes = urls[urls.length - 1].imageUrl # highRes
      if opts.support.imgset
        el.style.backgroundImage = 'url(' + highRes + ')'
        el.setAttribute('style', el.style.cssText + 'background-image: -webkit-image-set(' + mapUrls(urls).join(', ') + ');')
        cleanSources(el)
      else
        el.dataset.src = highRes
    else if src
      el.style.backgroundImage = 'url(' + src + ')'

    if el.classList.contains 'progressive-load'
      el.classList.remove 'progressive-load'

prepareSources = (el, opts) ->
  handleNormalImages(el, opts)
  handleProgressiveLoad(el, opts)
  handleBackgroundImages(el, opts)

cleanSources = (el) ->
  el.removeAttribute('data-src')
  el.removeAttribute('data-srcset')
  el.classList.remove 'lazy-wl', 'lazy-load'
  if el.classList.contains 'progressive-load'
    el.classList.remove 'progressive-load'

module.exports = {
  cleanSources
  mapOptions
  prepareSources
}
