'use strict'

vanillaLazy = require 'vanilla-lazyload/dist/lazyload'
{ cleanSources, mapOptions, prepareSources } = require './helper'

lazyStore = {}
defaultClasses = ['.lazy-load', '.lazy-wl']

skipLazyLoader = (selector, context, options) ->
  context ?= document
  Array.from(context.querySelectorAll(selector)).forEach (el) ->
    opts = mapOptions(options)
    prepareSources el, opts
    {src, srcset} = el.dataset
    if src or srcset
      if src
        el.src = src
      if srcset
        el.srcset = srcset
      el.dataset['wasProcessed'] = true
      el.classList.add 'lazy-loaded'
    cleanSources el

lazyload = (selector, args...) ->
  if args.length is 1
    [options] = args
  if args.length is 2
    [context, options] = args

  options ?= {}

  if options.skip_invisible is false or options.instant_load is true
    skipLazyLoader(selector, context, options)
    return

  Array.from(document.querySelectorAll(selector)).forEach (el) ->
    if not el.src
      el.src = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="

  if typeof selector is 'string'
    knownSelector = selector.split(/[\s]/g).find (entry) -> entry in defaultClasses

    if knownSelector
      selector = knownSelector
    options.selector = selector

  if (instance = lazyStore[selector]) instanceof vanillaLazy
    instance.update()
  else
    lazyStore[selector] = new vanillaLazy(mapOptions(options))

init = ->
  if window.jQuery and window.$
    $.fn.lazyload = (options = {}) ->
      options.container = @context

      lazyload @selector, options

  lazyload

module.exports = {
  init
}
