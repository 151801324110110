'use strict'

checkExperiment = (expName, callback) ->
  if typeof expName is 'object'
    expName = if I18n.country is 'US' then expName.US else expName.EMEA

  Finch.experiment expName, callback


module.exports = checkExperiment
