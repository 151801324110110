'use strict'

# trackEvent:
module.exports = (eventName, payload = {}) ->
  if typeof payload isnt 'object'
    payload = { data: payload }

  Object.assign(payload, { pageId: location.pathname })

  if (b = OptimizeSuite?.config?.user?.browserId)
    Object.assign(payload, { b })

  OptimizeSuite?.TrackingHub?.add("#{eventName}", payload)
  return
