export const NOOP = 'NOOP';
export const INITIALIZE = 'INITIALIZE';
export const CHANGE_LOCATION_HASH = 'CHANGE_LOCATION_HASH';

export const SHOW_PANE = 'SHOW_PANE';
export const HIDE_PANE = 'HIDE_PANE';
export const CLEAR_SCROLL_POSITION = 'CLEAR_SCROLL_POSITION';

export const SHAREPANEL_SHOW = 'SHAREPANEL_SHOW';
export const SHAREPANEL_HIDE = 'SHAREPANEL_HIDE';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const PRE_SELECT_CATEGORY = 'PRE_SELECT_CATEGORY';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const POST_SELECT_CATEGORY = 'POST_SELECT_CATEGORY';
export const PRE_CLEAR_CATEGORY_SELECTION = 'PRE_CLEAR_CATEGORY_SELECTION';
export const CLEAR_CATEGORY_SELECTION = 'CLEAR_CATEGORY_SELECTION';
export const POST_CLEAR_CATEGORY_SELECTION = 'POST_CLEAR_CATEGORY_SELECTION';
export const REST_CATEGORY_SELECTION = 'REST_CATEGORY_SELECTION';

export const OPEN_SEARCH = 'OPEN_SEARCH';
export const SWITCH_SEARCH_CONTEXT = 'SWITCH_SEARCH_CONTEXT';
export const SEARCH_INPUT_FOCUS = 'SEARCH_INPUT_FOCUS';
export const SEARCH_INPUT_BLUR = 'SEARCH_INPUT_BLUR';

export const DEALS_SUGGEST_REQUEST = 'DEALS_SUGGEST_REQUEST';
export const DEALS_SUGGEST_SUCCESS = 'DEALS_SUGGEST_SUCCESS';
export const DEALS_SUGGEST_FAILURE = 'DEALS_SUGGEST_FAILURE';
export const UPDATE_DEAL_SEARCH_KEYWORD = 'UPDATE_DEAL_SEARCH_KEYWORD';

export const LOCATIONS_SUGGEST_REQUEST = 'LOCATIONS_SUGGEST_REQUEST';
export const LOCATIONS_SUGGEST_SUCCESS = 'LOCATIONS_SUGGEST_SUCCESS';
export const LOCATIONS_SUGGEST_FAILURE = 'LOCATIONS_SUGGEST_FAILURE';
export const UPDATE_LOCATION_SEARCH_KEYWORD = 'UPDATE_LOCATION_SEARCH_KEYWORD';

export const UPDATE_RECENT_SEARCHES = 'UPDATE_RECENT_SEARCHES';
export const UPDATE_RECENT_LOCATIONS = 'UPDATE_RECENT_LOCATIONS';

export const CURRENT_POSITION_REQUEST = 'CURRENT_POSITION_REQUEST';
export const CURRENT_POSITION_SUCCESS = 'CURRENT_POSITION_SUCCESS';
export const CURRENT_POSITION_FAILURE = 'CURRENT_POSITION_FAILURE';
export const UPDATE_CURRENT_POSITION_SUCCESS =
  'UPDATE_CURRENT_POSITION_SUCCESS';

export const SEARCH_SUBMIT = 'SEARCH_SUBMIT';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';

export const RVD_REQUEST = 'RVD_REQUEST';
export const RVD_SUCCESS = 'RVD_SUCCESS';
export const RVD_FAILURE = 'RVD_FAILURE';
export const RVD_BUCKET = 'RVD_BUCKET';

export const UPDATE_IN_APP_MESSAGES = 'UPDATE_IN_APP_MESSAGES';
export const FETCH_IN_APP_MESSAGES_FAILURE = 'FETCH_IN_APP_MESSAGES_FAILURE';
export const BLOCK_IN_APP_MESSAGES_BANNER = 'BLOCK_IN_APP_MESSAGES_BANNER';
export const MARK_NOTIFICATIONS_AS_VIEWED = 'MARK_NOTIFICATIONS_AS_VIEWED';
export const UPDATE_CART_SIZE = 'UPDATE_CART_SIZE';
export const UPDATE_UNVIEWED_COUNT = 'UPDATE_UNVIEWED_COUNT';
