'use strict'

SearchBarView = require './views/desktop_search'
Location = require '../../../../../src/frontend/helper/search/helper/m_location_helper'
ClosestDivision = require '../../../../../src/frontend/helper/search/common/m_closest_division'
{setTranslations} = require '../../../../../src/frontend/helper/search/helper/m_translations.coffee'
Geocoder = require '../../../../../src/frontend/helper/search/common/m_geocoder'

# Groupon.Modules.SearchBar:
SearchBar = {
  Autocomplete: {}

  init: ->
    Location.setDivision(Groupon.division)
    setTranslations(Groupon.LS.translations)

    if $('form#ls-search-form').length
      new SearchBarView()
}

window.Groupon.Utils ?= {}
Object.assign Groupon.Utils, {
  ClosestDivision
  Geocoder
}

window.Groupon.Modules ?= {}

module.exports = SearchBar
