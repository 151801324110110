import { combineReducers } from 'redux';

import app from 'app/common/reducers/app';
import t from 'app/common/reducers/t';
import flags from 'app/common/reducers/flags';
import auth from 'app/common/reducers/auth';
import inAppMessages from 'app/common/reducers/inAppMessages';
import notifications from 'app/common/reducers/notifications';
import location from 'app/common/reducers/location';

const rootReducer = combineReducers({
  app,
  t,
  auth,
  flags,
  inAppMessages,
  notifications,
  location,
});

export default rootReducer;
