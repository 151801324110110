'use strict'

checkExperiment = require 'src/frontend/helper/experiment'

loadAsyncJS = require('./m_loadAsyncJS')
{ unescape } = require './m_underscore_replacements'
{ readLdJSON } = require('./m_readLdJSON')

((gtm) ->
  gtmData = readLdJSON 'gtmData'
  cCookie = Cookie.get 'c'
  eCookie = Cookie.get 'e'
  if (gtmData.data)
    try
      gtm = JSON.parse(unescape(unescape(gtmData.data)))

  if (cCookie and eCookie)
    gtm.dl_user_id = cCookie
    gtm.dl_email_sha256 = eCookie

  gtm.platform = gtmData.platform
  window.dataLayer = [gtm]
  if (gtmData.enabled)
    loadGTM = ->
      checkExperiment 'LS-4100-intentmedia-gtm', Treatment: -> window.dataLayer.push({'LS-4100-intentmedia-gtm':true})
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      })
      src = new URL('https://www.googletagmanager.com/gtm.js')
      if (gtmData.id)
        src.searchParams.set('id', gtmData.id)
      loadAsyncJS src.toString()
    window.addEventListener 'load', loadGTM, { passive: true, once: true }
)({})
