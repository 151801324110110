'use strict'

attrShow = {
  'aria-hidden': false
  'tabindex': 0
}
attrHide = {
  'aria-hidden': true
  'tabindex': -1
}

###*
# Toggle visibility class on adjacent label / placeholder
#
# @param {object} $input   - Input jQuery selector
# @param {string} [action] - Action to add / remove or toggle (default) visibility class (optional)
###
setPlaceholderState = ($input, action = '') ->
  return unless $input?.length

  # if the input has a placeholder attribute give it precedence of the label
  action = 'hide' if $input.attr('placeholder')

  $placeholder = $input.siblings('.ls-flex-placeholder')
  return unless $placeholder?.length

  switch action
    when 'hide'
      $placeholder.addClass('isHidden')
    when 'show'
      $placeholder.removeClass('isHidden')
    else
      $placeholder.toggleClass('isHidden')
  return

overwritePlaceholder = ($input, text) ->
  return unless $input?.length

  text ?= $input.val()
  $input.siblings('.ls-flex-placeholder').text(text)

###*
# Wrapper to toggle visibility of adjacent label / placeholder
#
# @param {object} $input   - Input jQuery selector
# @param {string} [action] - Action to add / remove or toggle (default) visibility class (optional)
###
togglePlaceholderState = ($input, action = '') ->
  return unless $input?.length

  if action
    setPlaceholderState($input, action)
  else
    if $input.val() is ''
      setPlaceholderState($input, 'show')
    else
      setPlaceholderState($input, 'hide')
  return

###*
# AX - Function to toggle visibility of jQuery element
#
# @param {object} $el  - Element jQuery selector
# @param {string} [cb] - Callback (optional)
###
axToggleVisiblityState = ($el, cb) ->
  return unless $el?.length
  isHidden = $el.attr('aria-hidden') is 'true'
  attr = if isHidden then attrShow else attrHide

  $el.attr attr
  cb?()
  return

###*
# AX - Function to hide jQuery element
#
# @param {object} $el  - Element jQuery selector
# @param {string} [cb] - Callback (optional)
###
axHide = ($el, cb) ->
  return unless $el?.length

  $el.attr attrHide
  cb?()
  return

###*
# AX - Function to show jQuery element
#
# @param {object} $el  - Element jQuery selector
# @param {string} [cb] - Callback (optional)
###
axShow = ($el, cb) ->
  return unless $el?.length

  $el.attr attrShow
  cb?()
  return

mapHTMLEncodes = {
  '&': '&amp;'
  "'": '&apos;'
  '"': '&quot;'
  '<': '&lt;'
  '>': '&gt;'
}
reHTMLEncode = new RegExp(Object.keys(mapHTMLEncodes).join('|'), 'g')

###*
# HTML encode
# https://stackoverflow.com/questions/7753448/how-do-i-escape-quotes-in-html-attribute-values
###
quoteattr = (s) ->
  ('' + (s or '')).replace(reHTMLEncode, (matched) -> mapHTMLEncodes[matched])

module.exports = {
  ax:
    show: axShow
    hide: axHide
    toggleVisibility: axToggleVisiblityState
  attrShow
  attrHide
  quoteattr
  placeholderState:
    overwrite: overwritePlaceholder
    set: setPlaceholderState
    toggle: togglePlaceholderState
}
