'use strict'

validator = require '../../../../../src/frontend/helper/validator'

Form =
  init: (signinFlyout) ->
    @signinFlyout = signinFlyout
    @$email = $('#ls-signin-email')
    @$password = $('#ls-signin-pw')
    @t = Groupon.LS.translations.userNav
    $('#ls-signin-form').submit (e) =>
      @submit(e)

    @$email.on 'blur', (e) =>
      @validateEmail(e)
    @$password.on 'blur.signin', (e) =>
      @validatePassword(e)
    @$email.on 'focus', (e) =>
      if not @$email.val() and @$email.parent().hasClass('error')
        GIG.Forms.addError(@t.signInFlyoutRequiredEmail, '#ls-signin-email')
    @$password.on 'focus', (e) =>
      if not @$password.val() and @$password.parent().hasClass('error')
        GIG.Forms.addError(@t.signInFlyoutRequiredPassword, '#ls-signin-pw')

  submit: (e) ->
    $('#ls-signin-error').text('').hide()
    e.preventDefault()
    emailValid = @validateEmail(e)
    passwordValid = @validatePassword(e)
    if not emailValid
      @$email.focus()
      return false
    else if not passwordValid
      @$password.focus()
      return false

    $('#ls-signin-error').hide()

    if (checkbox = document.getElementById('ls-remember-me'))?.checked
      checkbox.value = '1'

    $.ajax
      method: 'POST'
      url: '/login/iframe'
      dataType: 'json'
      data: $('#ls-signin-form').serialize()
      success: =>
        @signinFlyout.successMetric()
        Groupon.setWindowLocation window.location.href
      error: (err) =>
        @$password.val('').off('blur.signin').blur()
        @$password.parent().removeClass('success')
        $('#ls-signin-error').text(@t.signInFlyoutInvalidEmailOrPassword).show()
        @$password.on 'blur.signin', (ev) =>
          @validatePassword(ev)

  validateEmail: (e) ->
    email = @$email.val()

    if not email
      setTimeout( =>
        msg = if @$email.parent().hasClass('focused') then @t.signInFlyoutRequiredEmail else @t.signInFlyoutRequired
        GIG.Forms.addError(msg, '#ls-signin-email')
      , 10)
      return false

    if not validator.isEmail(email)
      GIG.Forms.addError(@t.signInFlyoutInvalidEmail, '#ls-signin-email')
      return false

    GIG.Forms.removeError '#ls-signin-email'
    GIG.Forms.success '#ls-signin-email'
    true

  validatePassword: (e) ->
    password = @$password.val()

    if not password
      setTimeout( =>
        msg = if @$password.parent().hasClass('focused') then @t.signInFlyoutRequiredPassword else @t.signInFlyoutRequired
        GIG.Forms.addError(msg, '#ls-signin-pw')
      , 10)
      return false

    GIG.Forms.removeError '#ls-signin-pw'
    GIG.Forms.success '#ls-signin-pw'
    true

module.exports = Form
