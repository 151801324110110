import * as ActionTypes from 'app/common/constants/action-types';
import { loadInAppMessages } from 'app/common/actions/inAppMessages';
import timeAgoTranslations from 'app/common/helpers/timeAgoTranslations';
import { loadAuth } from 'app/common/actions/auth';

/** Init state by server side data and local data */
export function init(initState) {
  return {
    type: ActionTypes.INITIALIZE,
    payload: initState,
  };
}

export function lazyLoad() {
  return (dispatch, getState) => {
    return new Promise(resolve => {
      const state = getState();

      dispatch(loadAuth()); // load user auth info

      timeAgoTranslations.importTranslations(state.app.lang);

      // load iam
      Promise.all([dispatch(loadInAppMessages())])
        .then(() => resolve())
        .catch(() => {});
    });
  };
}
