'use strict'

# Groupon.Utils.unparam:
module.exports = (str = location.search) ->
  unless typeof str is 'string'
    throw new TypeError "unparam needs a string, but got #{JSON.stringify str}"
  data = {}

  keyPairs = str.replace(/\+/g, '%20').split('&')
  for keyVal in keyPairs
    continue unless tuple = /^\??([^=]*)=(.*)/.exec keyVal
    try data[decodeURIComponent tuple[1]] = decodeURIComponent tuple[2]
  data
