'use strict'

module.exports = ->
  # uncomment content of 3rd party app wrapper -> executes 3rd party JS / css
  $('#subsUI, .app-third-party-assets').contents().filter( ->
    # filter all nodes that are comments (nodeType = 8)
    return this.nodeType is 8
  ).each( (i, e) ->
    # uncomment and append to parent wrapper
    $(e.parentElement).append(e.nodeValue)
    # delete comment
    $(e).remove()
    return
  )
  return
