'use strict'

trackEvent = require '../../../../../src/frontend/helper/general/m_trackEvent'

Facebook = require './facebook'
Google = require './google'
Form = require './form'

SignInFlyout =
  init: ->
    @$menu = $('#ls-user-signin-with-flyout')
    @$flyout = $('#ls-signin-flyout')
    return unless @$menu.length and @$flyout.length

    @hideFlyoutTimer = null
    @isActive = false
    @fetchLegalVersions() if Groupon.LS.experiments?.showGDPRSigninFlyout

    # defer 3rd party login code execution
    Google.init(this)
    Facebook.init(this)

    Form.init(this)

  bind: ->
    $(document).on
      keyup: (e) =>
        if e.keyCode is Groupon.Keymap.ESCAPE # esc
          @hideFlyoutWithoutDelay()
      click: (e) =>
        @hideFlyoutIfClickOutside(e)

    if Groupon.LS.hasTouch
      # LS-1179 make flyout accessible for touch
      @$menu.on 'touchend' , (e) =>
        e.preventDefault()
        e.stopImmediatePropagation()
        # close other header menus
        $('html').trigger 'tap'
        if @$flyout.hasClass 'show'
          @hideFlyoutWithoutDelay()
        else
          @showFlyout()
          # close visible subnav when tapping any element but the flyout
          $('html').off('tap.signin').one 'tap.signin', (evt) =>
            @hideFlyoutIfClickOutside(evt)

    @$menu.on 'focus', =>
      @showFlyout()

    @$menu.add(@$flyout).on
      'mouseover': =>
        @showFlyout()
      'mouseout': =>
        @hideFlyout()

    @$menu.add(@$flyout.find('a, button, input')).on
      'focus': =>
        @isActive = true
      'blur': =>
        @isActive = false

  hideFlyoutIfClickOutside: (e) ->
    if $(e.target).closest('.signin-flyout-menu-item, .ls-signin-flyout, .ls-signin-form').length == 0
      @hideFlyoutWithoutDelay()

  showFlyout: ->
    @$flyout.addClass('show')
    if @hideFlyoutTimer
      window.clearTimeout @hideFlyoutTimer

  hideFlyoutWithoutDelay: ->
    @$flyout.removeClass('show')

  hideFlyout: ->
    return if @isActive
    window.clearTimeout @hideFlyoutTimer
    @hideFlyoutTimer = window.setTimeout( =>
      @hideFlyoutWithoutDelay()
    , 250)

  successMetric: ->
    try
      OptimizeSuite.InteractionGoals.successMetric({ type: 'layout_login', channel: 'other' })

  fetchLegalVersions: ->
    url = "/layout/calls/legal-cms-service/get_version?filter=privacyPolicy,termsOfService"
    fetch(url, { credentials: 'same-origin' })
      .then (res) ->
        if !res.ok
          throw({ error: 'legal-cms-service failed'})
        return res.json()
      .then (json) ->
        if (gdprEl = document.getElementById('ls-signin-flyout-gdpr'))
          gdprEl.dataset.privacyVersion = json.data.privacyPolicy
          gdprEl.dataset.termsVersion = json.data.termsOfService
      .catch (e) ->
        trackEvent('legal-cms-service-failure', { error: e })

module.exports = SignInFlyout
