'use strict'

unparam              = require '../../general/m_unparam'
{ whichKeyAlias }    = require '../common/m_keypress'
{ placeholderState } = require '../helper/m_common'
{ isBlank } = require '../../../old/common/underscore_mixins'


module.exports = class KeywordBar
  constructor: ({$form, env}) ->
    @$form = $form
    @env = env or 'desktop'
    @$input = @$form.find('#ls-search')
    @placeholder = @$input.attr('placeholder')

    @bind()

    unless isBlank(initialKeyword = unparam().query)
      placeholderState.set(@$input, 'hide')
      @val initialKeyword
    return

  bind: ->
    @$input.on 'keydown', (e) =>
      if whichKeyAlias(e) is 'Enter'
        @$form.trigger 'submit'
      return

  setKeyword: @val

  val: (value) ->
    if value?
      @$input
        .val(value)
        .removeClass('prompting') # prompting plugin doesn't respect setting value programmatically in firefox
    else
      currentVal = @$input.val()

      return null if isBlank(currentVal) or currentVal is @placeholder
      currentVal.trim()

# Groupon.Modules.SearchBar.KeywordBar:
# FIXME: this is ugly - there must be a smarter way
window.Groupon.Modules.SearchBar ?= {}
Object.assign window.Groupon.Modules.SearchBar, {KeywordBar}
