/* eslint no-underscore-dangle: 0 */
import createStore from 'app/desktop/store';
import renderComponent from 'app/common/helpers/renderComponent';
import NotificationComponent from 'app/desktop/components/notifications/Notifications';
import InAppMessagesRoot from 'app/desktop/components/inAppMessages/inAppMessages';

import { init, lazyLoad } from 'app/desktop/actions/app';
import { parsed } from 'src/frontend/core/browser_events';

const store = createStore();

const initClient = () => {
  require('preact/devtools');
  // // init app data
  store.dispatch(init(window.__LS_INITIAL_STATE__));
  // // lazy load data from storage
  store.dispatch(lazyLoad());

  // render root components

  renderComponent(
    store,
    NotificationComponent,
    'ls-search-bar-wrapper',
    'ls-header-inbox-flyout-container'
  );
  renderComponent(store, InAppMessagesRoot, 'ls-in-app-messages');
};

parsed.then(initClient);
