'use strict'

ClosestDivision    = require './m_closest_division'
{ presentAddress, fixLatOrLng } = require '../helper/m_location_helper'
{ getAddressComponentType
, getAlternateAddressFormat
} = require '../../general/specialAddressComponentType'

G_MAP_TYPES = [
  'country',
  'street_address',
  'intersection',
  'political',
  'administrative_area_level_1',
  'administrative_area_level_2',
  'administrative_area_level_3',
  'administrative_area_level_4',
  'administrative_area_level_5',
  'colloquial_area',
  'locality',
  'ward',
  'sublocality',
  'neighborhood',
  'premise',
  'subpremise',
  'natural_feature',
  'airport',
  'park',
  'point_of_interest',
  'subpremise',
  'postal_code',
]

# Groupon.Utils.Geocoder:
module.exports = class Geocoder

  geocode: (options = {}) ->
    {address, location, country, current_location} = options
    return unless address? or location?
    ClosestDivision.abort()
    @getLatLng {
      address: address  unless current_location
      location: location if current_location
      country
      success: _.partial(@_googleGeocodeSuccessHandler, options)
      error: _.partial(@_googleGeocodeErrorHandler, options)
    }

  getLatLng: (options = {}) ->
    {success, error} = options

    Groupon.Utils.GoogleMaps.load (err) ->
      {address, location, success, error} = options
      return error?() if err?

      requestOptions = { location }
      if address?
        requestOptions = { address }

      new window.google.maps.Geocoder().geocode requestOptions, (results, status) ->
        success? { results, status }

  fetchClosestDivision: (options) ->
    {address, lat, lng} = options
    success = _.partial @_closestDivisionSuccessHandler, options
    error = _.partial @_closestDivisionErrorHandler, options

    ClosestDivision.getClosestDivision {loc: address, lat, lng, success, error }


  _googleGeocodeSuccessHandler: (options, googlegeocoderData) =>
    {address, geocoderError, country, current_location} = options
    {results, status} = googlegeocoderData
    # error handling when bad address
    if status isnt 'OK'
      status = if status is 'ZERO_RESULTS'
        'BAD_LOCATION'
      else
        'UNAVAILABLE'

      return geocoderError({address, status})

    unless current_location
      if country is 'US'
        valid_countries = ['US', 'CA']
      else
        valid_countries = [country]
      # Filter results for address components
      results = results.filter ({address_components}) ->
        address_components.some ({short_name}) ->
          short_name in valid_countries

    # all-country filtering results
    # see all types: https://developers.google.com/maps/documentation/geocoding/#Types
    #
    # filtered types
    # street_address: Oberwallstraße 6
    # locality: Chicago
    # sublocality: Manhatten
    # postal_code: 99099
    # political: California
    # country: Canada

    relevantTypes = ({ types }) ->
      types.some (type) -> type in G_MAP_TYPES

    unless result = results.find relevantTypes
      return geocoderError {address, status: 'BAD_LOCATION'}

    formated_address = if country is 'JP'
      getAlternateAddressFormat(result)
    else
      result.formatted_address
    friendlyName = presentAddress formated_address
    lat = fixLatOrLng(result.geometry.location.lat())
    lng = fixLatOrLng(result.geometry.location.lng())

    geocoderData = {
      fullAddress: friendlyName
      friendlyName # PWA expects this to be in the `search_loc` cookie
      lat: lat
      lng: lng
      locality: getAddressComponentType(result, 'locality')?.long_name
      administrative_area: getAddressComponentType(result, 'administrative_area_level_1')?.short_name
      sublocality: getAddressComponentType(result, 'sublocality_level_1')?.short_name
      postal_code: getAddressComponentType(result, 'postal_code')?.short_name
      neighborhood: getAddressComponentType(result, 'neighborhood')?.short_name
      types: result.types
    }
    # set user lat/lng instead of Google Maps
    if current_location
      {lat, lng} = options.location
      geocoderData.lat = lat
      geocoderData.lng = lng
    {lat, lng} = geocoderData
    options = Object.assign {}, options, {geocoderData, lat, lng}
    @fetchClosestDivision(options)

  # only called when gmaps unavaible
  _googleGeocodeErrorHandler: (options, status) ->
    {geocoderError} = options

    if status is 'abort'
      return geocoderError(status: 'ABORT')

    geocoderError
      address: options.address
      status: 'UNAVAILABLE'

  _closestDivisionSuccessHandler: (options, response) ->
    {address, query, geocoderData, geocoderSuccess, current_location} = options

    if response?.status isnt 'ok' and not response.geoData?
      return options.geocoderError {address, data: geocoderData, status:'BAD_LOCATION'}

    {lat, lng, closest_division: closestDivision} = response.location
    locationData = Object.assign {}, geocoderData, {lat, lng, closestDivision}

    geocoderSuccess {
      address
      query
      locationData
      current_location
    }

  _closestDivisionErrorHandler: (options, xhr, status) ->
    {geocoderData, address, geocoderError} = options

    if status is 'abort'
      error = {status: 'ABORT'}
    else
      error = {address, data: geocoderData, status: 'UNAVAILABLE'}
    geocoderError error
