'use strict'


###*
# @type {Object} allTranslations Global closure to cache translations
###
allTranslations = {}

###*
# Get translations
#
# @param   {String} [key]
# @returns {Object} translations - All translations or partial
###
getTranslations = (key) ->
  if not Object.keys(allTranslations).length
    setTranslations(Groupon.LS?.translations)

  translations = if typeof key is 'string'
    allTranslations[key]
  else
    allTranslations

  translations or {}

###*
# Cache translations globally and make immutable
#
# @param   {Object} [translations]
# @returns {Object} allTranslations
###
setTranslations = (translations) ->
  translations ?= Groupon?.LS.translations
  allTranslations = Object.seal(translations)

module.exports = {
  getTranslations
  setTranslations
}
