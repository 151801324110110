'use strict'

changeUserLanguage = require '../../../../../../src/frontend/old/groupon_modules/changeUserLanguage'
{ matchMQ } = require '../../../../../../src/frontend/helper/mediaQueries/mediaQueries'

mediaQuery = Groupon.LS.mediaQueries.slsLMax

module.exports = class ResponsiveMenu

  constructor: ->

    @$menu = $('#ls-resp-menu')

    mqCallback = => @initialize()

    if matchMQ(mediaQuery)
      @initialize()
    else
      matchMedia(mediaQuery).addListener(mqCallback)

  initialize: =>
    return if @$menu.children().length
    @$body = $('body')
    @$headerRow = $('#ls-header-top-row')
    @$overlay = $('#ls-resp-overlay')
    @$searchBar = $('#ls-search-bar-wrapper')
    @$userMenu = $('#ls-my-stuff')
    @$responsiveMenuWrapper = $('#ls-resp-menu-wrapper')
    @popoverActiveClass = 'responsive-show-500'
    @popoverTriggerActiveClass = 'ls-resp-active'

    @$menuTrigger = $('#ls-resp-menu-trigger')
    @$searchTrigger = $('#ls-resp-search-trigger')
    @$backButton = $('#ls-resp-back-button')

    @unbind()
    @$subnavs = @constructSubNavFlyouts()
    $primaryNav = @constructPrimaryNavigation(@$subnavs)
    $userNav = @constructUserNavigation(@constructUserMenu(), @constructLanguageMenu())
    @$menu.append( $primaryNav, $userNav )
    @dedupIDs()
    @alterDOM()
    @bind()

  constructPrimaryNavigation: (subNavs) ->
    $primaryNav = $('.primary-nav > li')
    .clone()
    .attr(
      role: 'menuitem'
      style: null
      class: 'ls-resp-menu-item'
    ).each ->
      # connect subnav to primary nav item
      id = 'subnav-' + @id
      for subnav in subNavs
        if subnav.id is id and subnav.children.length
          subnav.id = 'ls-resp-' + @id.replace('-tab', '-menu') # make id unique
          # remove aria attributes
          $(subnav).attr
            'aria-labelled': null
            'aria-labelledby': null
            'aria-hidden': null
          $this = $(this)
          # remove id, which got cloned
          # remove aria attributes - we have a logical markup representing
          # link/tab -> menu relationship
          $this.children('a').attr
            id: null
            role: null
            tabindex: null
            'aria-haspopup': null
            'aria-owns': null
          # set caret + append subnav
          $this.addClass('icon-arrow-down-large').append(subnav)
      @id = 'ls-resp-' + @id.replace('-tab', '-trigger')
    $primaryNav.find('.icon-home').removeClass()

    $primaryNav = $primaryNav.map( ->
      $this = $(this)
      $subnav = $this.find('.ls-resp-submenu')
      if not $subnav.find('a').length
        $subnav.remove()
        $this.removeClass('icon-arrow-down-large')
      this
    )

    $primaryNav

  constructSubNavFlyouts: ->
    $subNavs = $('.subnav-flyout').clone().attr(
      'aria-hidden': 'true'
      role: 'menu'
      class: 'ls-resp-submenu hide'
      style: null
    )
    $subNavs.each (i, flyout) ->
      $flyout = $(flyout)
      $links = $flyout.find('.subnav-categories > a, .flyMenu > li > a')
      $links.each (i, link) ->
        $(link).attr({class: 'ls-resp-subnav-link'})
      $flyout.html $links
    $subNavs

  constructUserNavigation: ($userMenu, $langMenu) =>
    $userNav = $('.ls-user-item, .user-menu-item').not('.lang-menu').clone()
    .wrap('<li class="ls-resp-menu-item"></li>').parent()

    #lang selector & menu
    $userNav = $langMenu.add($userNav) if $langMenu.length

    # logged in user
    if ($userName = $userNav.find('.user-name')).length
      $userName.attr(
        href: null
        id: 'ls-resp-user-name'
        class: 'icon-user'
        role: null
        tabindex: null
        'aria-haspopup': null
        'aria-owns': null
        'aria-describedby': null
      ).text($userName.attr('data-user-name'))
      .parent().attr(
        id: 'subnav-usermenu-menu'
        class: 'ls-resp-menu-item ls-resp-menu-item-user icon-arrow-down-large'
      ).append($userMenu)
      $signOut = @$userMenu.find('#sign-out').clone().attr(
        class: null
      ).wrap('<li class="ls-resp-menu-item"></li>').parent()
      $userNav = $userNav.add($signOut)

    # cart
    if ($cart = $userNav.find('.cart-widget')).length
      $cart = $cart.find('a')
      $userNav.find('.cart-widget').replaceWith($cart)

    $userNav.find('.user-menu-item').removeClass()
    $userNav.eq(0).addClass('ls-resp-menu-divider')

    $userNav

  constructUserMenu: =>
    $userMenu = {}
    if @$userMenu.length
      $userMenu = @$userMenu.clone().attr({
        class: 'ls-resp-submenu hide'
        id: 'ls-resp-user-menu'
        role: 'menu'
        'aria-labelled': null
        'aria-labelledby': null
        'aria-hidden': null
      })

      $userMenu.find('#sign-out').remove()
      $userMenu.find('svg').remove()
      $userMenu.find('a').addClass('ls-resp-submenu-item').each (i, link) ->
        id = link.id
        $(link).attr {
          id: 'ls-resp-' + id
          class: 'ls-resp-subnav-link'
        }
      @$subnavs = @$subnavs.add($userMenu)
    $userMenu

  constructLanguageMenu: =>
    $langMenuSelector = {}
    if $('.lang-menu').length
      $langMenuSelector = $('<span />').text($('#ls-lang-selected').text().trim())
      .wrap('<li></li>')
      .parent().attr(
        id: 'ls-resp-lang-trigger'
        class: 'ls-resp-menu-item ls-resp-lang-trigger icon-arrow-down-large'
      )

      $langMenu = $('#ls-lang-list').clone()
        .attr(
          id: 'ls-resp-lang-menu'
          class: 'ls-resp-submenu hide'
          role: 'menu'
          'aria-labelled': null
          'aria-labelledby': null
          'aria-hidden': null
        )
        .off('mouseenter mouseleave')
      $langMenu.find('a').addClass('ls-resp-subnav-link').on 'click', (e) ->
        e.preventDefault()
        e.stopImmediatePropagation()
        language = $(this).data('locale')
        changeUserLanguage language
      $langMenuSelector.append($langMenu)
      # add menu to subnav list
      @$subnavs = @$subnavs.add($langMenu)

    $langMenuSelector

  # the new #ls-resp-menu-wrapper has a lot of duplicated id:s now; uniquify all
  dedupIDs: ->
    @$responsiveMenuWrapper.find('*[id]').each ->
      unless @id.match(/ls-resp-/)
        @id = "ls-resp-#{ @id }"

  alterDOM: =>
    $('.header').prepend([@$menuTrigger, @$backButton, @$searchTrigger])
    $('.header-v2').after(@$overlay)

    @$body.prepend(@$responsiveMenuWrapper)

  unbind: =>
    @$searchTrigger.off 'tap'
    @$menuTrigger.off 'tap'
    @$overlay.off 'tap'
    $('.ls-resp-submenu').parent('li').off 'tap'
    $('.ls-resp-submenu a').off 'tap'
    @$menu.empty()

  bind: =>
    @$searchTrigger.on 'tap', (e) =>
      e.preventDefault()
      e.stopImmediatePropagation()
      {menuVisible} = @getVisibility()
      if menuVisible
        @hideRespMenu()
      else
        @toggleOverlay()
        @$body.toggleClass('noscroll')
      @$overlay.addClass('ls-resp-overlay-search-v3')
      @$searchTrigger.toggleClass(@popoverTriggerActiveClass)
      @$searchBar.toggleClass(@popoverActiveClass)
      $('#ls-search').focus()

    @$menuTrigger.on 'tap', (e) =>
      e.stopImmediatePropagation()
      {searchVisible} = @getVisibility()
      if searchVisible
        @hideRespSearch()
      else
        @toggleOverlay()
        @$body.toggleClass('noscroll')
      @$body.toggleClass('show-responsive-menu')
      @$overlay.removeClass('ls-resp-overlay-search-v3')
      @$menuTrigger.toggleClass(@popoverTriggerActiveClass)
      @toggleAriaHidden(@$responsiveMenuWrapper)


    @$overlay.on 'tap', (e) =>
      e.preventDefault()
      @hideRespSearch()
      @hideRespMenu()
      @hideOverlay()

    $navWithMenu = $('.ls-resp-submenu').parent('li')
    $navWithMenu.on 'tap', (e) =>
      $tab = $(e.currentTarget)
      # get open subnavs and hide it
      setTimeout( =>
        $visibleSubnavs = @$subnavs.not('.hide').addClass('hide')
        $navWithMenu.removeClass('ls-resp-open-menu')

        setTimeout( ->
          $flyout = $tab.children('.ls-resp-submenu')
          # test if menu exist and if this menu wasn't open before this event
          if $flyout.length and not $flyout.is($visibleSubnavs)
            $tab.addClass('ls-resp-open-menu')
            $flyout.removeClass('hide')
        , 150)
      , 150)

    $('.ls-resp-submenu a').on 'tap', (e) ->
      e.stopImmediatePropagation()

  toggleAriaHidden: ($el) ->
    ariaHidden = $el.attr('aria-hidden')
    ariaHidden = if ariaHidden is 'true' then 'false' else 'true'
    $el.attr('aria-hidden', ariaHidden)

  getAttributes: (elements, attribute) ->
    attributeArray = []
    for el in elements
      attributeArray.push $(el).attr(attribute).parent('li').removeClass('ls-resp-open-menu')
    attributeArray

  getVisibility: =>
    searchVisible: @$searchBar.is(':visible')
    menuVisible: @$responsiveMenuWrapper.is(':visible')

  hideRespSearch: ->
    if (@$searchBar.is(':visible'))
      @$searchTrigger.removeClass(@popoverTriggerActiveClass)
      @$searchBar.removeClass(@popoverActiveClass)
  hideRespMenu: ->
    @$body.removeClass('show-responsive-menu')
    @$menuTrigger.removeClass(@popoverTriggerActiveClass)
  hideOverlay: ->
    @$overlay.hide()
    @$body.removeClass('noscroll')
  toggleOverlay: ->
    @$overlay.toggle()

  # remove aria attributes
  removeAria: (menu) ->
    $(menu).attr
      'aria-labelled': null
      'aria-labelledby': null
      'aria-hidden': null
