'use strict'

Location              = require './m_location_helper'
{ normalizeQuery }    = require './m_normalizeQuery'
{ saveRecentLocation }  = require './m_typeahead_helper'
{ omit
, pickByIdentity }    = require '../../general/m_underscore_replacements'
redirectToUrl         = require '../../general/m_setWindowLocation'

TEN_YEARS_IN_MS = 1000 * 60 * 60 * 24 * 365 * 10

###*
# @type {Array} GEO_DATA Location attributes to exclude
###
GEO_DATA = [
  'administrative_area'
  'locality'
  'sublocality'
  'neighborhood'
  'postal_code'
]

###*
# Splits address into address components (e.g "Chicago, IL" into "Chicago" and "IL")
#
# @param   {Object} data {address}               - Location object
# @returns {Object} data {address, geoCoderData} - Split address data
###
breakDownAddress = (data) ->
  address = (data?.address?.split(',') or []).map (s) -> s.trim()
  data = omit data, GEO_DATA

  if not data.currentLocation
    # closest division success handler likes geocoder data, which is then set as
    # search_loc cookie
    geocoderData = { friendlyName: data.address, fullAddress: data.address }
    extendedData = switch address.length
      when 1
        { postal_code: address[0] }
      when 2
        {
          locality: address[0]
          administrative_area: address[1]
        }
      when 3
        {
          neighborhood: address[0]
          locality: address[1]
          administrative_area: address[2]
        }

    geocoderData = Object.assign {}, geocoderData, extendedData
  data.geocoderData = geocoderData or {}
  data

###*
# Returns link if query matches deal override config
#
# @param   {String}      query          - User query
# @param   {boolean}     [isMobile2016] - Flag indicating mobile2016 (optional)
# @param   {String}      divisionId     - Division id (optional)
# @returns {Null|String} link           - Link for matching query
###
findSearchOverridesLink = (query, isMobile2016 = false, divisionId) ->
  searchOverrides = window.Groupon.LS.data?.searchOverrides or []
  if divisionId
    url = getGeoSearchOverrideUrl query, divisionId, false
    return url if url
  if searchOverrides?.length and query
    searchOverrides = searchOverrides.filter((item) -> not item.hasOwnProperty('locations'))
    query = query.toLowerCase().trim()

    for {link, terms} in searchOverrides
      continue unless query in terms
      return link
  null

###*
# Get division object if existent
#
# @returns {Object} {lat, lng, fullAddress, closestDivision} Returns division object
###
getLocationForSearch = ->
  if division = Location.getDivision()
    {
      lat: division.lat
      lng: division.lng
      fullAddress: Location.userFriendlyLocation(division)
      closestDivision: division.id
    }

###*
# Parametrizes and picks unique url arguments
#
# @param   {Object} params - Url parameter
# @returns {String}        - Parametrized url arguments
###
getFormattedQueryString = (params = {}) ->
  if typeof params.query is 'object'
    params.query = params.query.value or ""
  params.locale = document.head.getAttribute('data-locale')
  $.param(pickByIdentity(params)).replace(/\s|%20/g, '+')

###*
# Build PULL url from arguments to complete search submit
#
# @param   {String} divisionId - Unique division name
# @param   {Object} params     - Url parameter
# @returns {String}
###
getSearchUrl = (divisionId, params) ->
  urlParams = getFormattedQueryString params
  "/browse/#{ divisionId }?#{ urlParams }"

###*
# Build PULL url from arguments to complete search submit
#
# @selectedResult {object} result data from html element
# @param   {String} divisionId - Unique division name
# @param   {Object} params     - Url parameter
# @returns {String}
###
getSearchUrlForCategoryItem = (selectedResult, divisionId, params) ->
  if not Object.keys(selectedResult).length
    return null
  { channel = null, level = null, permalink = null, value = null } = selectedResult
  if level < 2 then level = ''
  value = value?.replace /<.*?>/g, ''
  if channel == 'goods'
    url = "/goods/#{ permalink }?query=#{ value }"
  else if channel == 'local' or channel == 'travel'
    Object.assign params, { "category#{ level }": permalink, query: value }
    urlParams = getFormattedQueryString params
    url = "/browse/#{ divisionId }?#{ urlParams }"
  else
    return null
  url

### *
###
saveLocationAndRedirect = (place) ->
  { lat, lng, fullAddress, division } = place

  locationData = Object.assign(place, {
    friendlyName: fullAddress,
    address: fullAddress
  })

  Location.setELLCookie("#{lat},#{lng}")
  Location.removeGeolocationData('current_location')
  Location.setSearchLocCookie(locationData)
  storeLocationData(locationData)
  Cookie.set 'division', division, { maxAge: TEN_YEARS_IN_MS, consentClass: 'essential' }
  delete place.closestDivision
  redirectToUrl getSearchUrl(division, place)

###*
# LS-750 and LS-765
# Returns link if query matches location override config
#
# @param   {String}      query   - User query
# @param   {String}      address - Address
# @param   {boolean}     addressNotSaved - the address has not been saved
# @returns {Null|String} link    - Link for matching query
###
getGeoSearchOverrideUrl = (query, address, addressNotSaved = true) ->
  searchOverrides = Groupon.LS.data?.searchOverrides or {}
  if searchOverrides?
    address = normalizeQuery(address?.toLowerCase().trim())
  switch typeof(query)
    when "string"
      nQuery = normalizeQuery(query.toLowerCase().trim())
    when "object"
      nQuery = normalizeQuery(query.value.toLowerCase().trim())
  for { link, terms, locations, saveLocation } in searchOverrides
    # do not redirect if we need to save the location first and have not saved the location
    if addressNotSaved and saveLocation
      continue
    if locations? and terms?
      locations = locations.map normalizeQuery
      terms = terms.map normalizeQuery
      # query and address match directly
      return link  if address in locations and nQuery in terms

      # test combinations of terms and locations against query
      for loc in locations
        for term in terms
          if nQuery in [term + '-' + loc, loc + '-' + term]
            return link
  null

###*
# Returns link if query matches override config
#
# @param   {String}                            divisionId - Unique division name
# @param   {Object} {address, lat, lng, query} attributes
# @returns {Null|String}                       url        - Link for matching query
###
getSearchOverrideUrl = (divisionId, attributes, isMobile2016 = false) ->
  { address, lat, lng, query } = attributes
  if typeof query is "object"
    nquery = query.value
  else
    nquery = query
  url = findSearchOverridesLink nquery, isMobile2016, divisionId
  return unless url?
  # LS-233 and LS-269
  if url is '/travel' then url += '?lat={lat}&lng={lng}&query={query}&address={address}'

  for key, val of { address, lat, lng, query, division: divisionId }
    url = url.replace('{' + key + '}', val)
  url.replace /\s/g, '+'

###*
# Returns link if query matches location override config
#
# @param   {Object}      {address} - Attributes
# @returns {Null|String}           - Link for matching query
###
getLocationOverrideUrl = (attributes) ->
  { address } = attributes
  overrides = Groupon.LS.data?.locationOverrides or {}
  if overrides?
    for {link, locations} in overrides
      continue  unless address in locations
      query = getFormattedQueryString attributes
      return "#{ link }?#{ query }"

  null

removeGeolocationData = -> Location.removeGeolocationData('current_location')

###*
# Writes location data to browser's localStorage
#
# @param   {boolean}     setCurrentLocation
# @param   {Object}      data               - Location data
# @returns {Null|String}                    - Link for matching query
###
storeGeolocationData = (setCurrentLocation, data) ->
  if setCurrentLocation
    Location.setGeolocationData('current_location', data)
  else
    removeGeolocationData()

## Save location data
storeLocationData = (data) ->
  { lat, lng, address } = data
  locData = { lat, lng, address }
  locData.division = data.division or data.closestDivision
  saveRecentLocation(locData)

module.exports = {
  breakDownAddress
  findSearchOverridesLink
  getFormattedQueryString
  getGeoSearchOverrideUrl
  getLocationForSearch
  getLocationOverrideUrl
  getSearchOverrideUrl
  getSearchUrl
  GEO_DATA
  removeGeolocationData
  storeGeolocationData
  storeLocationData
  saveLocationAndRedirect
  getSearchUrlForCategoryItem
}
