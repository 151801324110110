'use strict'

require '../../../src/frontend/desktop_sls'

responsiveMenu = require '../../gig_body/header/v6.0/responsive_menu/frontend/responsive_menu.coffee'
Groupon.LS.responsiveMenu = ->
  new responsiveMenu()
Groupon.LS.Header = (Groupon.LS.Header || {})
Groupon.LS.Header.PNResponsiveMenu = responsiveMenu
require '../../../app/desktop/client'
