'use strict'
loadAsyncJS = require '../../../src/frontend/helper/general/m_loadAsyncJS'
{ escape } = require('../../../src/frontend/helper/general/m_underscore_replacements')

module.exports =
  init: ->
    unless document.head.querySelector("meta[name='dfp-manual']")
      Groupon.DFPAds.load()

  load: ->
    return unless Groupon.DFPAds.isValidConsentCookie()

    h = document.head
    return unless h.querySelector("meta[name='dfp-slot']") or h.querySelector("meta[name='dfp-target']")

    # decode the meta tag specs for how we should target ads
    googletag.cmd.unshift ->
      read = (s) ->
        s = escape s
        try
          s = JSON.parse(s)
        s

      getAttrs = (metaData, type, names...) ->
        return  unless metaData.name is type
        data = {}
        for name in names
          try data[name] = read metaData.getAttribute "data-#{ name }"
        data

      metatags = h.getElementsByTagName('meta')

      for meta in metatags
        if (data = getAttrs(meta, 'dfp-slot', 'target', 'size', 'div'))
          googletag.defineSlot(data.target, data.size, data.div).addService(googletag.pubads())
        else if (data = getAttrs(meta, 'dfp-target', 'key', 'val'))
          googletag.pubads().setTargeting(data.key, data.val)

      googletag.pubads().enableSingleRequest()
      googletag.pubads().collapseEmptyDivs()
      googletag.enableServices()

    loadAsyncJS '//www.googletagservices.com/tag/js/gpt.js'
