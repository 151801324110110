'use strict'

PLATFORMS = {
  FB: 1
  GOOGLE: 2
}

getConsentsPayload = (privacyVersion, termsVersion, platform) ->
  metadata = { "login": if platform is PLATFORMS.FB then "facebook" else "google" }
  sharedProps = {
    "workflowType": "registration"
    "eventType": "accept"
    "logType": "consent"
    "metadata": metadata
  }

  return [
    Object.assign({
      "termsType": "privacyPolicy"
      "termsTypeVersion": privacyVersion?.toString() or '-1'
    }, sharedProps),

    Object.assign({
      "termsType": "termsOfService"
      "termsTypeVersion": termsVersion?.toString() or '-1'
    }, sharedProps)
  ]

getFBConsentsPayload = (privacyVersion, termsVersion) ->
  return getConsentsPayload(privacyVersion, termsVersion, PLATFORMS.FB)

getGoogleConsentsPayload = (privacyVersion, termsVersion) ->
  return getConsentsPayload(privacyVersion, termsVersion, PLATFORMS.GOOGLE)

getLegalVersionsFromDOM = ->
  privacyVersion = '-1'
  termsVersion = '-1'
  if (gdprEl = document.getElementById('ls-signin-flyout-gdpr'))
    privacyVersion = gdprEl.dataset.privacyVersion?.toString() or '-1'
    termsVersion = gdprEl.dataset.termsVersion?.toString() or '-1'
  return { privacyVersion, termsVersion }

module.exports = {
  getGoogleConsentsPayload
  getFBConsentsPayload
  getLegalVersionsFromDOM
}
