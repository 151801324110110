'use strict'

loadAsyncJS = (url, args..., cb) ->
  return if not url
  [ id ] = args
  doc = window.document
  s = doc.createElement('script')
  s.async = true
  s.src = url
  s.id = id if typeof id is 'string'
  s.onload = cb if typeof cb is 'function'
  (doc.body || doc.head).appendChild(s)
  return

# Groupon.Utils.loadAsyncJS:
module.exports = loadAsyncJS
