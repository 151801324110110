'use strict'


window.Groupon = {} unless window.Groupon?
Groupon.Performance ?= {}

Groupon.Performance.pageReady = ->
  if typeof BOOMR.page_ready is 'function'
    BOOMR.page_ready()
  else
    BOOMR.t_done = new Date().getTime()
