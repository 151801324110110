'use strict'

# Groupon.Utils.getConfigFromDom:
module.exports = (id = 'domConfig') ->
  el = document.getElementById(id)
  return {} if not el
  text = (el.textContent || el.innerText).trim()
  # textContent is more performant but only supported on IE 9+
  # see http://www.kellegous.com/j/2013/02/27/innertext-vs-textcontent/

  try
    JSON.parse text
  catch error
    error.message += 'Attempted to parse:' + text
    throw error
