'use strict';

const STORAGE_KEY = 'LS_consentActions';

const getConsentActions = (exports.getConsentActions = () => {
  let consentActions;
  try {
    consentActions = JSON.parse(localStorage.getItem(STORAGE_KEY));
  } catch (e) {
    consentActions = {};
  }
  return consentActions || {};
});

exports.storeConsentAction = (type, selection, version) => {
  const consentActions = getConsentActions();
  consentActions[type] = { selection, version };
  localStorage.setItem(STORAGE_KEY, JSON.stringify(consentActions));
};

exports.clearConsentActions = () => {
  localStorage.removeItem(STORAGE_KEY);
};
