import * as ActionTypes from 'app/common/constants/action-types';
import { CALL_API } from 'app/common/middleware/api';
import { getConsumerId } from 'app/common/helpers/cookie';

const sessionStorageKey = 'verifiedUser';

/**
 * Get authed user from session storage
 */
const getUserFromStorage = () => {
  let user = null;
  try {
    const userData = sessionStorage.getItem(sessionStorageKey);
    user = JSON.parse(userData);
    if (!user.id) {
      user = null;
    }
  } catch (__) {} // eslint-disable-line

  return user;
};

export const loadAuth = () => {
  // check snickerdoodle cookie first
  if (!getConsumerId()) {
    return {
      type: ActionTypes.USER_SUCCESS,
      payload: null,
    };
  }

  // load data from session storage second
  const user = getUserFromStorage();
  if (user) {
    return {
      type: ActionTypes.USER_SUCCESS,
      payload: user,
    };
  }

  // load
  return {
    [CALL_API]: {
      types: [
        ActionTypes.USER_REQUEST,
        ActionTypes.USER_SUCCESS,
        ActionTypes.USER_FAILURE,
      ],
      endpoint: '/layout/calls/users/v1',
      success: user => {
        if (user && user.id) {
          try {
            sessionStorage.setItem(sessionStorageKey, JSON.stringify(user));
          } catch (__) {} // eslint-disable-line
        }
        return user;
      },
    },
  };
};

// remove sessionStorage
export const signOut = () => {
  try {
    sessionStorage.removeItem(sessionStorageKey);
  } catch (__) {} // eslint-disable-line
  return () => {};
};
