import { loadItem, safeRemoveItem } from 'app/common/helpers/storage';
import { decodeUrl } from 'app/common/helpers/url';
import { GEOLOCATION } from 'app/common/constants/storage-keys';

export const USER_ID = 'snickerdoodle';
export const B = 'b';
export const C = 'c';
export const SEARCH_LOC = 'search_loc';
export const DIVISION = 'division';
export const ELL = 'ell';
export const CLL = 'cll';

// get user id from cookie
export function getUserId() {
  return Cookie.get(USER_ID);
}

// get b cookie
export function getB() {
  return Cookie.get(B);
}

// get c cookie
export function getC() {
  return Cookie.get(C);
}

// get ell cookie
export function getEll() {
  return Cookie.get(ELL);
}

// get cll cookie
export function getCll() {
  return Cookie.get(CLL);
}

/**
 * Set the ell cookie value
 * @param {string} ell - ell cookie value
 */
export function setEll(ell) {
  Cookie.set('ell', ell, { consentClass: 'essential' });
}

/**
 * Get "search_loc" cookie data
 * @returns {Object} - search_loc - Search_loc data
 */
export function getSearchLoc() {
  const payload = Cookie.get(SEARCH_LOC);
  let state = null;
  if (payload) {
    try {
      state = JSON.parse(decodeURIComponent(payload));
    } catch (e) { } // eslint-disable-line
  }

  if (!state) {
    return {};
  }

  const { current_location } = loadItem(GEOLOCATION, {});
  // we don't need friendlyName, just in case
  state.fullAddress = decodeUrl(state.fullAddress || state.friendlyName);
  // if we have local storage for current_location, use it then delete it.
  state.currentLocation = state.currentLocation || current_location;
  safeRemoveItem(GEOLOCATION); // don't need it anymore

  return state;
}

// get consumer id from cookie
// snickerdoodle first, then c cookie
export function getConsumerId() {
  return getUserId() || Cookie.get(C);
}

/**
 * Get lat & lng from search_loc cookie
 * Set it to ell
 */
export function setELLFromSearchLoc() {
  const { lat, lng } = getSearchLoc();
  if (!lat || !lng) {
    return;
  }
  if (lat && lng) {
    setEll(`${lat},${lng}`);
  }
}
