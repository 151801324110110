'use strict'

CartItems = require '../templates/cart_items.html.mustache'
CartEmpty = require '../templates/cart_empty.html.mustache'
CartSpinner = require '../templates/cart_spinner.html.mustache'

module.exports = class CartWidget
  constructor: (@el) ->
    @$el = $(@el)
    @useCartSizeEndpoint = Groupon?.LS?.flags?.useCartSizeEndpoint
    @snickerdoodle = Cookie.get 'snickerdoodle'
    if @$el.length
      @fetch()

  fetch: ->
    complete = @onComplete
    data =
      consumerId: @snickerdoodle if @snickerdoodle

    if @useCartSizeEndpoint
      url = Groupon.endpoints?.cartSize or '/cart/size'
      @$el.one 'mouseover', @fetchCartData
    else
      url = Groupon.endpoints?.cart or '/cart/widget'

    $.ajax {
      type: "GET"
      url: url
      data
      complete
    }

  fetchCartData: =>
    translations = Groupon.LS.translations.cart
    @$el.append CartSpinner.render {translations}
    complete = @renderCartData
    data =
      consumerId: @snickerdoodle if @snickerdoodle

    $.ajax {
      type: "GET"
      url: Groupon.endpoints?.cart or '/cart/widget'
      data
      complete
    }

  onComplete: (data, textStatus, xhr) =>
    {responseText} = data
    translations = Groupon.LS.translations.cart
    cartWidgetUrl = Groupon.Cart.cartWidgetUrl

    if textStatus is 'success'
      response = JSON.parse responseText
      {hasError, hasCart} = response
      if not hasError
        if hasCart
          @showCart {response, translations, cartWidgetUrl}
        else
          @showEmptyCart {translations} unless @useCartSizeEndpoint
        # LS-1179 make flyout accessible for touch
        # applies only for old responsive layout
        if Groupon.LS.hasTouch
          $cartFlyout = $('.cart-flyout')
          @$el.find('.icon-goods').on 'tap', (e) ->
            e.preventDefault()
            e.stopImmediatePropagation()
            $cartFlyout.toggle()
            # close other header menus
            $('html').trigger 'tap'
            if $cartFlyout.is(':visible')
              # register closing event once
              $('html').one 'tap', (event) ->
                if $(event.target).closest('#ls-header-cart-link').length == 0
                  $cartFlyout.hide().attr('aria-hidden', 'true')

    @$el.addClass('cart-widget-shows')
    delete window.CartWidget

  showCart: (data) ->
    @renderCartLink data
    @$el.append CartItems.render data unless @useCartSizeEndpoint

  renderCartLink: (data) ->
    {quantity_value, quantity} = data.response
    {translations} = data
    markup = """
    <span class='cart-text'>#{ translations.cart.desc } (#{ quantity })</span>
    <span class='cart-count'>#{ quantity_value }</span>
    """
    @$el.find('.icon-goods, a').html markup

  renderCartData: (data, textStatus, xhr) =>
    {responseText} = data
    translations = Groupon.LS.translations.cart
    cartWidgetUrl = Groupon.Cart.cartWidgetUrl

    @$spinnerFlyout = @$el.find('.cart-flyout.load-spinner')

    if textStatus is 'success'
      response = JSON.parse responseText
      {hasError, hasCart} = response
      if not hasError
        @$spinnerFlyout.remove()
        if hasCart
          @renderCartLink {response, translations}
          @$el.append CartItems.render {response, translations, cartWidgetUrl}
        else
          @showEmptyCart {translations}
      else
        @$spinnerFlyout.addClass('ls-widget-error').html translations.cart.cart_error
    else
      @$spinnerFlyout.addClass('ls-widget-error').html translations.cart.cart_error

  showEmptyCart: (data) ->
    @$el.append CartEmpty.render data
    @$el.find('#ls-cart-link').html data.translations.cart.cart if @useCartSizeEndpoint
