'use strict'

# The Google Maps JS library can only be loaded once per page, otherwise it throws an error.
# This utility function allows multiple apps to try to load the library and get notified when the library has been loaded.

MAP_PROXY_CHANNEL = 'layout-service' # This is for tracking purposes only.
MAP_PROXY_HOST_NA = '//mapproxy.groupon.com'
MAP_PROXY_HOST_EU = '//mapproxy-eu.groupon.com'

GOOGLE_MAPS_VERSION = 3
GOOGLE_MAPS_LIBRARIES = ['geometry'] # Check the Google Maps docs for details on libraries available.

loading = false
callbacks = []

loaded = ->
  cb() for cb in callbacks

getMapProxyHost = ->
  country = document.head.getAttribute('data-country')

  # Based on https://github.groupondev.com/itier-service-clients/itier-groupon-v2-client/blob/master/src/base_url.coffee
  if country in ['US']
    MAP_PROXY_HOST_NA
  else
    MAP_PROXY_HOST_EU

module.exports = {

  load: (cb) ->
    cb ?= ->
    return cb() if window.google?.maps?
    callbacks.push cb

    return if loading
    loading = true

    callbackName = 'layoutServiceGoogleMapsCallback'
    window[callbackName] = loaded

    query = $.param
      sensor: false
      v: GOOGLE_MAPS_VERSION
      libraries: GOOGLE_MAPS_LIBRARIES.join ','
      callback: callbackName
      region: document.head.getAttribute('data-country') or 'US'
      language: document.head.getAttribute('data-lang') or 'en-US'
      channel: MAP_PROXY_CHANNEL

    $.getScript("#{getMapProxyHost()}/maps/api/js?#{query}").fail ->
      cb(new Error('Unable to load Google Maps Library'))
}
