'use strict'

Location              = require '../helper/m_location_helper'
{ GEO_DATA }          = require '../helper/m_searchbar_helper'
{ placeholderState }  = require '../helper/m_common'
{ whichKeyAlias }     = require '../common/m_keypress'
{ isBlank }           = require '../../../old/common/underscore_mixins'
{ pick }              = require '../../general/m_underscore_replacements'

module.exports = class LocationBar
  constructor: ({$form, env}) ->
    @$form = $form
    @env = env or 'desktop'
    @previousValue = ''
    @originalValue = ''
    @$input = @$form.find('#ls-location')

    initialLocation = Location.getInitialLocation() # e.g. "Chicago"
    initialLocationData = Location.getInitialLocationData() # e.g. {lat: 41.8795, lng: -87.6243}

    data = {manual: false, city: Groupon.division?.name}
    data = Object.assign( {}, data, pick(initialLocationData, 'lat', 'lng', GEO_DATA...)) if initialLocationData
    @$input.data data

    unless isBlank(initialLocation)
      placeholderState.set(@$input, 'hide')
      @$input.val(initialLocation)
      @$input.attr('placeholder', initialLocation)
      @originalValue = initialLocation # store the location original value

      switch @env
        when 'mobile'
          $('.t-division-link a').text(initialLocation)

    @bind()
    switch env
      when 'desktop'
        @setupDesktop()
      when 'mobile'
        @setupMobile()

  setLocation: @val
  val: (value, options = {}) ->
    {silent} = options
    if value?
      @$input.val(value).removeClass('prompting')
      @trigger 'manual', value  unless silent
    else
      currentVal = @$input.val()
      return null  if isBlank currentVal

      currentVal.trim()

  bind: ->
    @$input.on
      'focusin.location': =>
        @storeLocation()
        if @previousValue is @originalValue
          @$input.removeAttr('placeholder')
          @$input.val ''
        return

      'focusout.location': =>
        @storeLocation()
        @$input.val @previousValue or @originalValue # restore previous input or the original value
        @$input.trigger('input.searchGeneral') # handle placeholder / clearing x state
        return

      'manual.location': =>
        # pass as an object to prevent jquery formatting the attribute name
        @$input.data manual: true
        return

      'input.location': =>
        unless @$input.data('manual') is true
          @$input.trigger('manual')
        return

  restoreLocation: ->
    if (value = @originalValue).length
      @$input.val(value)
      @$input.attr('placeholder', value)

  storeLocation: ->
    # store the previous value
    unless isBlank value = @$input.val()
      @previousValue = value

  setupDesktop: ->

  setupMobile: ->
    @$input.on
      'keydown': (e) =>
        if whichKeyAlias(e) is 'Enter'
          @$form.trigger 'submit', 'manual'

# Groupon.Modules.SearchBar.LocationBar:
# FIXME: this is ugly - there must be a smarter way
window.Groupon.Modules.SearchBar ?= {}
Object.assign window.Groupon.Modules.SearchBar, {LocationBar}
