import * as ActionTypes from 'app/common/constants/action-types';

const INITIAL_STATE = {
  user: null,
  error: null,
  isAuthenticated: false,
};

const loadUserSuccess = (state, action) => {
  const { payload: user } = action;
  return {
    ...state,
    user,
    error: null,
    isAuthenticated: user && user.id ? true : false,
  };
};

const loadUserFailure = (state, action) => {
  if (!action.error) {
    return state;
  }

  return {
    ...state,
    error: action.error,
    isAuthenticated: false,
  };
};

function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.USER_SUCCESS:
      return loadUserSuccess(state, action);
    case ActionTypes.USER_FAILURE:
      return loadUserFailure(state, action);
    default:
      return state;
  }
}

export default authReducer;
