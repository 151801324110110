'use strict'

LSTouch =
  init: ->
    lsTouchTimer = null
    $('body').on 'touchend', 'a.lstouch', (e) ->
      e.preventDefault()
      e.stopImmediatePropagation()
      # clear the previous
      if lsTouchTimer
        clearTimeout lsTouchTimer
      target = e.currentTarget
      touchTicket = target.touchTimestamp or 0
      timestamp = new Date().getTime()
      doubleTouch = (timestamp - touchTicket) < 300 # double touch
      if doubleTouch
        window.location = e.currentTarget.href
        return

      target.touchTimestamp = timestamp
      e.type = 'lstouch'
      lsTouchTimer = setTimeout (->
        $(target).trigger 'lstouch'
        return
      ), 300

module.exports = LSTouch
