'use strict'

CLOSEST_DIV_URL = '/layout/get_location_sort?callback=?'

# Groupon.Utils.ClosestDivision:
module.exports = {
  getClosestDivision: (options = {}) ->
    {loc, lat, lng, success, error} = options

    lang   = document.head.getAttribute('data-lang')
    locale = document.head.getAttribute('data-locale')

    @_xhr?.abort()
    @_xhr = $.getJSON CLOSEST_DIV_URL, {loc, lat, lng, lang, locale}, success
    @_xhr.fail error

  abort: ->
    @_xhr?.abort()
}
