'use strict'
v0 = require './doubleclick.v0'
v1 = require './doubleclick.v1'

# Groupon.DFPAds:
module.exports = Groupon.DFPAds =
  init: ->
    ver = Groupon.DFPAds.getDoubleClickVer()
    if ver == 'v1'
      v1.init()
    else
      v0.init()

  getDoubleClickVer: ->
    return Groupon.DFPAds.aiVer if Groupon.DFPAds.aiVer?
    ver = "v0"
    if document.head.querySelector("meta[name='ai-v1']")
      ver = "v1"
    Groupon.DFPAds.aiVer = ver
    return ver

  isValidConsentCookie: ->
    c3s = window.Cookie.get('c3s')
    return true unless c3s
    #only return false if regexp /^\d+-\d+-([0-1])$/g is matched, example 123423-2342342-(0|1)
    return c3s.match(/^\d+-\d+-([0-1])$/g) == null

  load: ->
    ver = Groupon.DFPAds.getDoubleClickVer()
    if ver == 'v1'
      v1.load()
    else
      v0.load()

