'use strict'

extendFlags = ->
  # get body classes
  bodyClasses = document.body.classList or $(document.body).getClasses()

  Object.assign window.Groupon, {
    App:
      name: $(document.head).attr('data-app')
    Browsers:
      chrome: !!window.chrome
      phantomJS: !!(window._phantom or window.callPhantom)
      safari: navigator.vendor?.indexOf('Apple') > -1 and not navigator.userAgent?.match('CriOS')
    PageFlags:
      isWeb: 'is-web' in bodyClasses
      isTouch: 'is-touch' in bodyClasses
      isLegacyResponsive: 'ls-resp' in bodyClasses
      isTouchRedesign: 't-redesign' in bodyClasses
      isTouchRequest: 'is-touch-request' in bodyClasses
  }

module.exports = ->
  if document.body?
    extendFlags()
  else
    $ -> extendFlags()
