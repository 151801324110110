'use strict'

module.exports = {

  # Groupon.Utils.externalObjectLoader:
  externalObjectLoader: (url, onloadScript, id = 'js-iframe-async') ->
    iframe = document.createElement('iframe')
    iframe.src = 'javascript:false'
    iframe.setAttribute('aria-hidden', 'true')
    iframe.hidden = "hidden"
    (iframe.frameElement || iframe).style.css = 'width: 0;height: 0;border: 0'
    iframe.frameBorder = 0
    where = document.getElementsByTagName('script')
    where = where[where.length - 1]
    where.parentNode.insertBefore(iframe, where)

    try
      doc = iframe.contentWindow.document
    catch
      iframe.src = """
        javascript:var d=document.open();d.domain='#{ document.domain }';void 0
      """
      doc = iframe.contentWindow.document
    doc.open()._load3po = ->
      @domain = dom if dom?

      scriptInit = @createElement('script')
      scriptInit.type = 'text/javascript'
      scriptInit.innerHTML = 'Groupon=window.parent.Groupon;$=window.parent.$'
      @body.appendChild scriptInit

      js = @createElement('script')
      js.type = 'text/javascript'
      js.id = id
      js.src = url
      if onloadScript?
        js.onload = onloadScript
      @body.appendChild(js)

    doc.write('<body onload="document._load3po();">')
    doc.close()

  # Groupon.Utils.externalObjectLoaderOnready:
  externalObjectLoaderOnready: (fn) ->
    if typeof fn is 'function'
      $(document).ready(fn)

}
