'use strict'

{ uniqueId } = require '../../helper/general/m_underscore_replacements'

Groupon.Keymap =
  'TAB'       : 9
  'ENTER'     : 13
  'ESCAPE'    : 27
  'SPACE'     : 32
  'END'       : 35
  'HOME'      : 36
  'LEFT'      : 37
  'UP'        : 38
  'RIGHT'     : 39
  'DOWN'      : 40
  'DELETE'    : 46

forEach = Array::forEach

module.exports = class window.Groupon.AriaMenuNavigation
  initAriaMenuNavigation: (menuTrigger, menu, menuLinks, menuTriggerParent) ->
    @menuTrigger = menuTrigger
    @menu = menu
    @menuLinks = menuLinks

    @initAriaAttributes(menuTrigger, menu, menuLinks, menuTriggerParent)
    @bindMenu(menuTrigger)

  initAriaAttributes: (menuTrigger, menu, menuLinks, menuTriggerParent) ->
    @isControlledByClass = menu.classList.contains('ls-hide')
    # check if menu exists
    return if not menuExists = !!menu

    # make sure the menu & menuTrigger have an unique id
    if not menu.id
      menu.id = uniqueId('sls-aria-')

    if not menuTrigger.id
      menuTrigger.id = uniqueId('sls-aria-')

    menuTrigger.setAttribute('role', 'menuitem')
    menuTrigger.setAttribute('aria-haspopup', menuExists)
    menuTrigger.setAttribute('aria-owns', menu.id)
    menuTrigger.setAttribute('tabindex', '0')

    triggerWrapper = @ariaFindClosestParent(menuTrigger, menuTriggerParent)
    triggerWrapper.setAttribute('role', 'menubar')

    return if not menuExists

    if menuLinks.length
      menu.setAttribute('role', 'menu')
      menu.setAttribute('aria-hidden', 'true')
      menu.setAttribute('aria-labelledby', menuTrigger.id)

      forEach.call(menuLinks, (link) ->
        link.setAttribute('tabindex', '-1')
        link.setAttribute('role', 'menuitem')
      )
    return

  ariaFindClosestParent: (source, selector = '') ->
    # find closest parent element for the menu trigger
    # skip assumption if @menuTriggerParent is given
    unless selector
      if source.parentNode.nodeName is 'LI'
        # assuming ul-li parent structure
        selector = 'ul'
      else
        # assuming nav html5 parent structure
        selector = 'nav'
    # if no element is found search for the closest div
    source.closest(selector) or source.closest('div')

  bindMenu: (menuTrigger) ->
    @ariaBindMenuTrigger(menuTrigger)
    @ariaBindMenuLinks()
    return

  ariaBindMenuTrigger: (trigger = @menuTrigger) ->
    handler = (e) =>
      { SPACE, DOWN } = Groupon.Keymap
      if e.keyCode in [SPACE, DOWN]
        @menu.setAttribute('aria-hidden', 'false')
        if @isControlledByClass
          @menu.classList.remove('ls-hide')
        else
          @menu.style.display = 'block'

        firstLink = @menuLinks[0]
        if not firstLink
          return

        @menuLinks[0].focus()
        @menuLinks[0].setAttribute('tabindex', 0)

    trigger.removeEventListener('keydown', handler, { passive: true })
    trigger.addEventListener('keydown', handler, { passive: true })
    return

  ariaBindMenuLinks: ->
    handler = (e) =>
      keycode = e.keyCode
      { UP, DOWN, ESCAPE, TAB } = Groupon.Keymap
      # if ESC, TAB pressed within the subnav: close menu + focus on trigger
      if keycode in [ESCAPE, TAB]
        @ariaCloseMenu(@menuTrigger)
        # focus previous link
      else
        linkIndex = $.inArray(e.target, @menuLinks)
        # key "arrow up"
        if keycode is UP
          # if key "arrow up" the first link -> close menu
          if linkIndex is 0
            # when focus is on the first link focus on last link
            @ariaResetIndex(linkIndex)
            @ariaBrowseLinks(@menuLinks.length - 1, 0)
          else
            # focus previous link
            @ariaBrowseLinks(linkIndex, -1)
        # key "arrow down"
        if keycode is DOWN
          if linkIndex is @menuLinks.length - 1
            #when focus was on the last link focus on the first link
            @ariaResetIndex(linkIndex)
            @ariaBrowseLinks(0, 0)
          else
            #focus on next link
            @ariaBrowseLinks(linkIndex, 1)
      return

    forEach.call(@menuLinks, (link) ->
      link.removeEventListener('keydown', handler, { passive: true })
      link.addEventListener('keydown', handler, { passive: true })
    )
    return

  ariaBrowseLinks: (i, sibling) ->
    @ariaResetIndex(i)
    link = @menuLinks[i + sibling]
    link.focus()
    link.setAttribute('tabindex', '0')
    return

  ariaResetIndex: (i) ->
    @menuLinks[i].setAttribute('tabindex', '-1')
    return

  ariaCloseMenu: (trigger = @menuTrigger) ->
    @menu.setAttribute('aria-hidden', 'true')
    if @isControlledByClass
      @menu.classList.add('ls-hide')
    else
      @menu.style.display = ''
    #reset tabindex
    forEach.call(@menuLinks, (link) -> link.setAttribute('tabindex', '-1'))
    trigger.focus()
    return
