'use strict'

AriaMenuNavigation = require '../../../../../src/frontend/old/groupon_modules/ariaKeyNavigation'
changeUserLanguage = require '../../../../../src/frontend/old/groupon_modules/changeUserLanguage'


module.exports = class LanguageSelector extends AriaMenuNavigation
  constructor: ->
    if not @trigger = document.getElementById('ls-lang-selected')
      return

    @menu = document.getElementById('ls-lang-list')
    @links = @menu.querySelectorAll('.lang-list-item')
    @$html = $('html')
    @hovTimeout = 0
    @bind()
    return

  bind: ->

    @initAriaMenuNavigation(@trigger, @menu, @links)

    Array::forEach.call(@links, (link) ->
      link.addEventListener('click', (e) ->
        e.preventDefault()
        e.stopImmediatePropagation()
        language = e.target.dataset.locale
        changeUserLanguage language
      )
    )

    toggleMenu = =>
      if @menu.classList.contains('ls-show')
        @showMenu()
      else
        @hideMenu()

    @trigger.removeEventListener('touchstart', toggleMenu)
    @trigger.addEventListener('touchstart', toggleMenu)
    return

  showMenu: ->
    @trigger.classList.add('ls-active')
    @menu.setAttribute('aria-hidden', 'false')
    @menu.classList.add('ls-show')
    return

  hideMenu: ->
    @menu.setAttribute('aria-hidden', 'true')
    @menu.classList.remove('ls-show')
    @trigger.blur()
    @trigger.classList.remove('ls-active')

  setGlobalHideEvent: ->
    @$html.one 'tap', (e) =>
      if not $(e.target).closest('.lang-menu').length
        @hideMenu()
