'use strict'

{ getRecentSearches
, moveAutocomplete
, removeRecentSearch
, removeRecentSearches
, setRecentSearches
} = require '../helper/m_typeahead_helper'
{ ax } = require '../helper/m_common'
{ normalizeQuery } = require '../helper/m_normalizeQuery'
typeaheadBuilder = require '../helper/m_typeahead_builder'
{ unescape }  = require '../../general/m_underscore_replacements'
{ fixLatOrLng } = require '../helper/m_location_helper'
#placesStorage = require '../../../../../modules/my_places/frontend/places_storage'

module.exports = class Typeahead
  constructor: (opts) ->
    {
      env:                    @env
      endpoints:              @endpoints
      experiments:            @experiments
      resultList:             @$container
      options: flags
    } = opts

    @query = ''
    @ctx = 'search'
    @$form = $('#ls-search-form')
    @$inputs = @$form.find('input')
    @$search = @$form.find('#ls-search')
    @$location = @$form.find('#ls-location')
    @once = true
    @lat = Groupon?.division?.lat
    @lng = Groupon?.division?.lng
    {
      locale: @locale
      country: @country
    } = I18n
    @useAltSearch = @country is 'US'
    @consumer_id = Cookie.get 'snickerdoodle'
    @result = []
    @ajaxRequest = null # Ajax request for autocomplete

    # Building AutocompleteList at most once per every 500 milliseconds
    fn = (e, ctx) => @buildAutocompleteList(e, ctx)
    @buildAutocompleteDelayed = _.throttle(fn, 500)

    @hasRecentSearches = false
    @hasRecentLocations = false
    @hasRentlyViewedDeals = false
    @passingCoordinateForDealsSearch = undefined
    @hasGeolocation = true

    {
      enableBetterUx:         @hasBetterUx
      fetchWithPlacesApi:     @fetchWithPlacesApi # LS-3106 use places api for autocomplete #LS-4025
    } = flags

    # recent searches
    if flags.enableRecentSearches
      @getRecentSearches()

    if flags.enableRecentLocations
      @getRecentLocations()
    @typeaheadBuilder = new typeaheadBuilder()
    @setBHViewport() if Groupon.LS?.hasTouch
    @bind()

  bind: ->
    @$container.on 'click', '.tw-item .tw-clear', (e) =>
      e.preventDefault()
      e.stopImmediatePropagation()
      $item = $(e.currentTarget).siblings('.tw-link')
      return unless (value = $item.attr('data-value'))

      ctx = @ctx
      removeRecentSearch(unescape(value), ctx)
      @rerender(e, ctx)
      return

    @$container.on 'click', '.tw-heading .tw-clear', (e) =>
      ctx = @ctx
      removeRecentSearches(ctx)
      @rerender(e, ctx)
      return

    return

  # LS-3434
  checkIfQuery: (query) ->
    if query?.length < 3 or not /[a-z]/gi.test(query)
      return false
    true

  # Check if we send lat/lon for deal search
  checkCoordinateExperiment: ->
    return unless @passingCoordinateForDealsSearch is undefined

    expName = if @country is 'US' then 'LS-3928-AutocompleteLatLong' else 'LS-3928-AutocompleteLatLong_international'

    # LS-3928
    Finch?.experiment(expName, {
      Control: =>
        @passingCoordinateForDealsSearch = false
      Treatment: =>
        @passingCoordinateForDealsSearch = true
    })

  addCoordinate: (ajaxData) =>
    if (cookie = Cookie.get('cll') or Cookie.get('ell'))?
      [lat, lng] = cookie.split(',')
    else
      lat = @lat
      lng = @lng

    Object.assign ajaxData, { lat: fixLatOrLng(lat), lng: fixLatOrLng(lng) }

  ###*
  # Fetch relevance API (deal / location)
  #
  # @param {String} query - Term to search
  ###
  fetch: (query) ->
    return if $('.ls-error-dialog').length
    @query = query
    isAddressesAutocomplete = @fetchWithPlacesApi and @ctx is 'location' # LS-3106
    if isAddressesAutocomplete and not @checkIfQuery(query) # LS-3434
      @success({locationsAutocompleteResults: []})
      return

    if not isAddressesAutocomplete && @once
      @once = false
      @checkCoordinateExperiment() # check experiment

    ajaxData =
      input: query
      limit: 10

    if @ctx is 'location' or @passingCoordinateForDealsSearch
      ajaxData = @addCoordinate(ajaxData)

    if isAddressesAutocomplete # LS-3106
      url = [ Groupon.endpoints?.places_autocomplete, 'v1' ].join '/'
      Object.assign ajaxData, {
        division: Groupon.division?.id or ''
      }
      successHandle = ({data, err}) =>
        return @reset() if err
        @success({locationsAutocompleteResults: data.addressesAutocompleteResults})
    else
      if @env is 'desktop'
        if @useAltSearchCategories is undefined
          @useAltSearchCategories = false
          Finch.experiment 'LS-4007-InCategoryAutocomplete', {
            Treatment: => @useAltSearchCategories = true
          }
      else if @env is 'mobile'
        if @useAltSearchCategories is undefined
          @useAltSearchCategories = false
          Finch.experiment 'LS-4007-InCategoryAutocompleteTouch', {
            Treatment: => @useAltSearchCategories = true
          }
      if @useAltSearch and not @useAltSearchCategories
        url = [ Groupon.endpoints?.autocomplete, 'alt-deal-search', 'v1' ].join '/'
      else if @useAltSearchCategories
        url = [ Groupon.endpoints?.autocomplete, 'alt-deal-search-categories', 'v1' ].join '/'
      else
        url = [ Groupon.endpoints?.autocomplete, @ctx , 'v1' ].join '/'
      successHandle = ({data, err}) =>
        return @reset() if err
        @success(data)

    ajaxConfig =
      url: url,
      isMobile: @env is 'mobile'
      headers: { 'x-country': I18n.country }
      data: ajaxData
      success: successHandle
      error: => @reset

    # cancel previous request
    @ajaxRequest?.abort()
    @ajaxRequest = $.ajax ajaxConfig
    return

  ###*
  # Success handler for relevance API call.
  # Selects and updates response.
  #
  # @param {Object} res - Object containing API response for either deal or location
  ###
  success: (res) =>
    # remove ajax request handler
    @ajaxRequest = null
    return if $('.ls-error-dialog').length
    { locationsAutocompleteResults: locations
    , dealsAutocompleteResults: deals
    } = res
    result = []
    if deals?.length
      result = [
        {
          collection: deals
          widget: 'autocomplete'
        }
      ]
    else if locations?.length
      result = [
        {
          collection: locations
          widget: 'autocomplete'
        }
      ]

    # spawn current location link in location result
    if @hasGeolocation and locations
      result.unshift({
        collection: []
        widget: 'geolocation'
      })

    if result.length
      @result = _.deepClone(result)
    @buildView @result, @query
    return

  ###
  # Generate typeahead html and append to typeahead container
  # It implements with mushdash templates
  #
  ###
  buildViewWithTemplate: (collection, query) ->
    $container = @$container
    html = ''
    config = {
      showAlt: !!@showAltRecentSearches
      isDesktop: @env is 'desktop'
      isLoggedIn: !!sessionStorage.getItem 'verifiedUser'
#      places: placesStorage
    }

    query = normalizeQuery(query)
    switch @ctx
      when 'search'
        bhw = 'dealAutocomplete'
        #@$search.attr('aria-activedescendant', prefix + '0') if prefix
        @$search.removeAttr('aria-busy')
        html = @typeaheadBuilder.buildSearchView collection, query, config
      when 'location'
        bhw = 'locationAutocomplete'
        #@$location.attr('aria-activedescendant', prefix + '0') if prefix
        @$location.removeAttr('aria-busy')
        html = @typeaheadBuilder.buildLocationView collection, query, config

    if (isRendered = !!html)
      $container
        .removeClass('typeahead-search typeahead-location')
        .addClass('typeahead-' + @ctx)
        .html(html)
        .attr({'data-bhw': bhw, 'data-bhw-path': null})

      moveAutocomplete($container, @ctx, @env)
      $container.show()
      ax.show($container, @removeTabIndex)

      if config.isDesktop
        @highlightMatch($container)
    else
      ax.hide($container)
      $container.hide()
    return isRendered

  ###*
  # Generate typeahead html and append to typeahead container
  #
  # @param   {Array}   collections - Updated
  # @param   {String}  query
  # @returns {boolean}             - State whether a collection is rendered
  ###
  buildView: (collections, query) ->
    @reset()
    return false if not collections.length

    return @buildViewWithTemplate(collections, query)

  rerender: (e, ctx) =>
    switch ctx
      when 'search'
        @getRecentSearches()
        e?.currentTarget.id = 'ls-search'
      when 'location'
        @getRecentLocations()
        e?.currentTarget.id = 'ls-location'

    @buildAutocompleteList(e, ctx)
    return

  # don't make the container selectable
  removeTabIndex: => @$container.removeAttr('tabindex')

  enableRecentLocations: (e) ->
    @getRecentLocations()
    @rerender(e, 'location')

  ###
  # Clear typeahead list
  ###
  reset: ->
    # remove ajax request handler
    @ajaxRequest = null
    @$container.find('ul').empty()
    ax.hide(@$container)
    @$inputs.removeAttr('aria-activedescendant').attr('aria-busy', 'true')
    @result = []
    return

  hide: ->
    @reset()
    #required to show empty ul for ARIA navigation
    @$container.hide().attr('aria-hidden', 'true').empty().html('<ul id="autocomplete-list" aria-busy="true"></ul>')

  ###*
  # Method triggered by deal / location input events to decide
  # the type of typeahead and emit relevance ajax request
  #
  # @public
  # @param {Object} e   - jQuery event
  # @param {String} ctx - Context: 'search' or 'location' input
  ###
  buildAutocompleteList: (e, ctx) =>
    e?.preventDefault()

    @ctx = ctx
    {id, value: query} = e.currentTarget

    if e.type is 'focusin' and id is 'ls-location'
      query = ''
    else
      query = query?.trim() or ''

    if not query
      # cancel previous request if the query is empty
      @ajaxRequest?.abort()
      @reset()
      hasRendered = switch id
        when 'ls-search'
          @showDealWidget()
        when 'ls-location'
          @showLocationWidget()
        else
          false

      if not hasRendered and @env is 'desktop'
        @$container.hide() # required for EMEA to avoid an empty container showing

    else
      switch e.type
        when 'input'
          # NOTE: The condition is necessary due to Android firing an "input" event when the value of the input has
          # NOTE: changed and the input is blurred. The condition prevents the autocomplete from being cleared so any
          # NOTE: autocomplete item can still be clicked
          @fetch(query) if query isnt @query
        else
          @fetch(query)

    return

  getRecentSearches: ->
    @recentKeywords = getRecentSearches('deal')
    @hasRecentSearches = @recentKeywords?.length > 0

  getRecentLocations: ->
    locations = getRecentSearches('location')
    @recentLocations = locations.filter (e) -> e.hasOwnProperty('division')
    if @recentLocations?.length > 0
      # clear entries that don't have 'division' key
      if @recentLocations.length isnt locations.length
        setRecentSearches(@recentLocations, 'location')
      if typeof @recentLocations[0] is 'object'
        @hasRecentLocations = true
      else
        localStorage?.removeItem('LS_recentLocations')

  highlightMatch: ($list) ->
    # store the matched result so it can be restored on mouseleave
    $matchedItem = $list.find('.typeahead-match')
    return unless $matchedItem.length

    $list.on
      mouseenter: ->
        # 'blur' the focused result so we don't have multiple green items
        document.activeElement.blur()
        $matchedItem.removeClass 'typeahead-match-highlight'
      mouseleave: ->
        $matchedItem.addClass 'typeahead-match-highlight'

  ###*
  # Build configurable collections for deal typeahead when input is empty
  #
  # @returns {boolean} - State whether a collection is being rendered
  ###
  showDealWidget: ->
    collections = []
    hasRecentSearches = @hasRecentSearches

    if hasRecentSearches

      if hasRecentSearches
        keywords = @recentKeywords?.slice(0)
        collections.push.apply(collections, [
          {
            collection: keywords
            widget: 'recentSearches'
          }
        ])

    return @buildView(collections)

  ###*
  # Build configurable collections for location typeahead when input is empty
  #
  # @returns {boolean} - State whether a collection is being rendered
  ###
  showLocationWidget: ->
    collections = []
    hasGeolocation = @hasGeolocation
    hasRecentLocations = @hasRecentLocations
    hasBetterUx = @hasBetterUx

    if hasGeolocation or hasRecentLocations or hasBetterUx

      if hasGeolocation
        collections.push.apply(collections, [
          {
            collection: []
            widget: 'geolocation'
          }
        ])

      if hasBetterUx
        collections.push.apply(collections, [
          {
            collection: []
            widget: 'helperMessage'
          }
        ])

      if hasRecentLocations
        keywords = @recentLocations?.slice(0, 5)
        collections.push.apply(collections, [
          {
            collection: keywords
            widget: 'recentLocations'
          }
        ])

    return @buildView(collections)

  ###
  # Prevent bloodhound impression tracking on typeahead due to performance reasons
  ###
  setBHViewport: (state = 'ignore') ->
    @$container.attr('data-bh-viewport', state)
    return

# Groupon.Modules.SearchBar.View.Typeahead:
# FIXME: this is ugly - there must be a smarter way
window.Groupon.Modules.SearchBar ?= {}
window.Groupon.Modules.SearchBar.View ?= {}
Object.assign window.Groupon.Modules.SearchBar.View, {Typeahead}
