'use strict'

_.mixin module.exports =
  deepClone: (object) ->
    clone = _.clone object
    _.each clone, (value, key) ->
      if _.isObject value
        clone[key] = _.deepClone value
    clone

  isBlank: (obj) ->
    if _.isString(obj)
      not /\S/.test obj
    else
      _.isEmpty obj

  escapeRegExp: (str) ->
    if str?
      String(str).replace /([-.*+?^=!:${}()|[\]\/\\])/g, '\\$1'
    else
      ''
