'use strict';
const loadAsyncJS = require('../../../src/frontend/helper/general/m_loadAsyncJS');
const escape = require('../../../src/frontend/helper/general/m_underscore_replacements').escape;

module.exports = (function() {
  var h = document.head;

  var dfp = function(data) {
    // Check data integrity
    var placements = data.adPlacements;
    if (!(Array.isArray(placements))) {
      throw("Response doesn't have valid placements");
    }

    // Helper methods
    var read = function(s) {
      s = escape(s);
      try {
        s = JSON.parse(s)
      } catch (ignored) {}
      return s;
    };
    var getAttrs = function (metaData, type) {
      if (metaData.name !== type) {
        return;
      }
      var names = [];
      for (var i = 2, len = arguments.length; i < len; i++) {
        names.push(arguments[i]);
      }
      return names.reduce(function(collection, name) {
        collection[name] = read(metaData.getAttribute(`data-${name}`));
        return collection
      }, {});
    };
    // Parse data
    var metatags = Array.from(h.getElementsByTagName('meta'));
    var configs = metatags.reduce(function(collection, meta) {
      if ((data = getAttrs(meta, 'ai-dfp-target', 'key', 'val'))) {
        collection[data.key] = data.val;
      }
      return collection;
    }, {});
    googletag.cmd.unshift(function() {
      placements.forEach(function(placement) {
        if (placement.adType === 'external' && placement.adFormat === 'dfp') {
          var dfpContent = placement.dfpContent;
          googletag.defineSlot(dfpContent.adTarget, dfpContent.adSizes, dfpContent.id).addService(googletag.pubads());
          Object.assign(configs, dfpContent.configs);
        }
      });
      for (var k in configs) {
        if (!(configs.hasOwnProperty(k))) { continue; }
        googletag.pubads().setTargeting(k, configs[k]);
      }
      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();
      googletag.enableServices();
    });
    placements.forEach(function(placement) {
      googletag.cmd.push(function() {
        googletag.display(placement.dfpContent.id);
      })
    });
    // Load GPT tag
    loadAsyncJS('//www.googletagservices.com/tag/js/gpt.js');
  };

  return {
    init: function () {
      if (!document.head.querySelector("meta[name='ai-dfp-manual']")) {
        Groupon.DFPAds.load();
      }
    },

    load: function () {
      if (!(Groupon.DFPAds.isValidConsentCookie())) return;

      if ((!(h.querySelector("meta[name='ai-slot']")))) {
        return;
      }
      var baseURLMeta = h.querySelector("meta[name='ai-base-url']");
      var baseURL = baseURLMeta ? baseURLMeta.getAttribute("data-base-url") : "https://www.groupondata.com/ai/api/v1/placement";
      var slotsMeta = h.querySelectorAll("meta[name='ai-slot']");
      var slots = [];
      var appNameMeta = h.querySelector("meta[name='ai-app-name']");
      var appName = appNameMeta ? appNameMeta.getAttribute("data-app-name") : "";
      var divisionMeta = h.querySelector("meta[name='ai-division']");
      var division = divisionMeta ? divisionMeta.getAttribute("data-division") : "";
      Array.prototype.forEach.call(slotsMeta, function (item) {
        slots.push(item.getAttribute("data-apif"));
      });
      var params = {
        app: appName,
        c_cookie: Cookie.get("c"),
        b_cookie: Cookie.get("b"),
        country: h.getAttribute("data-country"),
        locale: h.getAttribute("data-locale"),
        division: division,
        page: encodeURIComponent(location.href),
        platform: document.body.classList.contains("is-web") ? "web" : "touch",
        placements: slots.join('_')
      };
      var urlSearchParams = new URLSearchParams();
      Object.keys(params).forEach(function (key) {
        var value = params[key];
        if (value) {
          try {
            urlSearchParams.append(key, value);
          } catch (ignored) {}
        }
      });

      fetch(baseURL + '?' + urlSearchParams.toString()).then(function (res) {
        if (!res.ok) {
          throw("Response doesn't have status code 200");
        }
        return res.json();
      }).then(function (data) {
        dfp(data);
      }).catch(function (ignored) {
      });
    }
  };
})();
