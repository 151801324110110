'use strict'

TAB         = 9
ENTER       = 13
ESCAPE      = 27

DOWN_ARROW  = 40
RIGHT_ARROW = 39
UP_ARROW    = 38
LEFT_ARROW  = 37

KEYLIST =
  9:  'Tab'
  13: 'Enter'
  27: 'Escape'
  37: 'ArrowLeft'
  38: 'ArrowUp'
  39: 'ArrowRight'
  40: 'ArrowDown'


isTabKey       = (key) -> key is TAB
isEnterKey     = (key) -> key is ENTER
isEscapeKey    = (key) -> key is ESCAPE
isUpArrowKey   = (key) -> key is UP_ARROW
isDownArrowKey = (key) -> key is DOWN_ARROW

###*
# Assert if key pressed is a normal character key
#
# @param   {Object}  e - Event
# @returns {boolean}   - Assert if key pressed is a normal character key
###
isValidCharKeyPress = (e) ->
  return false unless e.which?
  return false if not isCharKeyPress(e)
  return false if not isValidKeyPress(e.which)

  true


###*
# Assert if key code is valid
# and wasn't pressed with extra control keys
#
# @param   {Object}  e - Event
# @returns {boolean}   - Assert if key wasn't pressed with control keys
###
isCharKeyPress = (e) ->
  if typeof e.which is 'number' and e.which > 0
    return !e.ctrlKey && !e.metaKey && !e.altKey
  false

###*
# Assert if key code is a navigational key
#
# @param   {Number}  key - Key code
# @returns {boolean}     - Assert if key is not a navigational key
###
isValidKeyPress = (key) ->
  not (key in [
    TAB
    ENTER
    ESCAPE
    UP_ARROW
    DOWN_ARROW
    LEFT_ARROW
    RIGHT_ARROW
  ])


###*
# Returns the key name according to the Keyboard API specs
# NOTE: e.key is not supported in Safari thus fallback to alias list
#
# @param   {Object} e - jQuery event
# @returns {String}   - Key name
###
whichKeyAlias = (e) ->
  e.key or KEYLIST[e.which] or ''

module.exports = Keypress = {
  isCharKeyPress
  isDownArrowKey
  isEnterKey
  isEscapeKey
  isTabKey
  isUpArrowKey
  isValidCharKeyPress
  isValidKeyPress
  whichKeyAlias
}

# Groupon.Modules.SearchBar.Common.Keypress:
# FIXME: this is ugly - there must be a smarter way
window.Groupon.Modules.SearchBar ?= {}
window.Groupon.Modules.SearchBar.Common ?= {}
Object.assign window.Groupon.Modules.SearchBar.Common, {Keypress}
