/*eslint-disable no-undef*/
__webpack_public_path__ = window.remoteUrl;

const importTranslations = lang => {
  let translation;
  switch (lang) {
    case 'it':
      translation = import(/* webpackChunkName: "timeago_it_translations" */ 'date-fns/locale/it');
      break;
    case 'de':
      translation = import(/* webpackChunkName: "timeago_de_translations" */ 'date-fns/locale/de');
      break;
    case 'fr':
      translation = import(/* webpackChunkName: "timeago_fr_translations" */ 'date-fns/locale/fr');
      break;
    case 'ja':
      translation = import(/* webpackChunkName: "timeago_ja_translations" */ 'date-fns/locale/ja');
      break;
    case 'nl':
      translation = import(/* webpackChunkName: "timeago_nl_translations" */ 'date-fns/locale/nl');
      break;
    case 'pl':
      translation = import(/* webpackChunkName: "timeago_pl_translations" */ 'date-fns/locale/pl');
      break;
    case 'es':
      translation = import(/* webpackChunkName: "timeago_es_translations" */ 'date-fns/locale/es');
      break;
    default:
      translation = import(/* webpackChunkName: "timeago_en_translations" */ 'date-fns/locale/en');
  }
  return translation;
};

const translation = {
  locale: null,
  importTranslations: lang => importTranslations(lang).then(setLocale),
};

function setLocale(module) {
  translation.locale = module;
}

module.exports = translation;
