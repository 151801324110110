'use strict'

flyoutMagicTriangle = require './flyout_magic_triangle'
activeClass = 'flyActive'

module.exports =
  init: ->
    $goodsTab = $('#subnav-goods-tab,#subnav-shop-tab')

    return if not (menuElem = $goodsTab[0]?.querySelector('.flyMenu'))

    flyoutOpts = {
      menuElem,
      rowSelector: '> li:not(.flyFlatLink)'
      delay: 200
      onActivateRow: (elem) ->
        elem.classList.add activeClass
      onDeactivateRow: (elem) ->
        elem.classList.remove activeClass
    }

    flyoutInstance = flyoutMagicTriangle flyoutOpts

    return unless flyoutInstance

    links = menuElem.querySelectorAll('li')
    nestedLinks = Array::filter.call(links, (link) -> !link.classList.contains('flyFlatLink'))
    nestedLinks.forEach((link) ->
      link.addEventListener('touchstart', (e) ->
        e.stopPropagation()
        e.preventDefault()
        flyoutInstance.activateRow e.target
      )
    )

    flyoutInstance.activateRow nestedLinks[0]
    return
