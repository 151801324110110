import { get } from 'app/common/helpers/fetch';

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API';

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (/* store */) => next => action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { endpoint, params, fetchOption, types, success, fail } = callAPI;

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }

  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }));

  const generateErrorPayload = error => {
    if (error instanceof Error) {
      return {
        error: 'System error.',
      };
    }

    return {
      error,
    };
  };

  return get(endpoint, params, fetchOption).then(
    response => {
      const payload = success ? success(response) : response;

      next(
        actionWith({
          type: successType,
          payload,
        })
      );
    },
    error => {
      if (fail) {
        fail(error);
      }

      next(
        actionWith({
          type: failureType,
          error: generateErrorPayload(error),
        })
      );
    }
  );
};
