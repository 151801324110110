'use strict';

const { matchesFuzzy, highlightWords } = require('./m_typeahead_helper_es6');
const { normalizeQuery } = require('./m_normalizeQuery.coffee');

const clearingPartial = require('./templates/partial_clearing.html.mustache');
const newBadgePartial = require('./templates/partial_new_badge.html.mustache');
const currentLocationPartial = require('./templates/partial_current_location.html.mustache');
const recentLocationPartial = require('./templates/partial_recent_location.html.mustache');
const autocompletePartial = require('./templates/partial_autocomplete.html.mustache');
const recentSearchPartial = require('./templates/partial_recent_search.html.mustache');

const locationTemplate = require('./templates/template_location.html.mustache');
const searchTemplate = require('./templates/template_search.html.mustache');

// Build the html of the autocomplete
class TypeAHeadBuilder {
  constructor() {
    const translations = Groupon.LS.translations;
    this.t = {
      currentlocation:
        translations.search && translations.search.currentlocation,
      remove: translations.ax.remove,
      clearall: translations.search.clearall,
      recentSearchs: translations.search.recentdeals,
      recentLocations: translations.search.recentlocations,
      categories: translations.categories || {},
      newBadge: translations.general.newbadge,
      // signIn: 'Sign in', // TODO: translation
      seeMore: 'See more',
    };
  }

  // Injects html tags to separate the neighborhood and the city
  deEmphasis(label = '', len = 2) {
    const res = label.split(/,(.+)?/);
    const parts = label.split(',');
    if (parts.length < len) {
      return label;
    }
    let tag = '<em>';
    if (label.indexOf('<strong>') > -1) {
      // strong tags present either in <neightborhood> or <city , state>"
      if (
        !(res[0].indexOf('</strong>') > -1 || res[1].indexOf('<strong>') > -1)
      ) {
        // strong tags present in both parts of the label
        tag = '</strong><em><strong>';
      }
    }
    res.splice(1, 0, tag);
    res.splice(3, 0, '</em>');
    return res.join('');
  }

  // Format tag
  prepareBloodhoundTag(tag = '') {
    return tag.toLowerCase().replace(/[^\w\d]+/g, '_');
  }

  // get all the default data
  getDefaultData(collections, query, config = {}) {
    const firstWidget = collections[0] && (collections[0].widget || '');

    this.experiments = {
      showAlt: config.showAlt,
    };

    let bh;
    if (firstWidget === 'autocomplete') {
      bh = {
        type: 'bhc',
        val: `UserTyped:${query}`,
      };
    }

    return {
      t: this.t,
      isDesktop: config.isDesktop,
      query,
      bh,
    };
  }

  // build the autocomplete view for deals search
  buildSearchView(collections, query, config = {}) {
    const data = Object.assign(
      this.getDefaultData(collections, query, config),
      {
        items: this.buildSearchItems(collections, query),
      }
    );

    const partials = {
      autocompletePartial,
      recentSearchPartial,
      clearingPartial,
      newBadgePartial,
    };

    return searchTemplate.render(data, partials);
  }

  buildSearchItems(collections, query) {
    const items = collections.reduce((results, { collection, widget }) => {
      switch (widget) {
        case 'autocomplete':
          return results.concat(this.buildAutocomplete(collection, query));
        case 'recentSearches':
          return results.concat(this.buildRecentSearches(collection, query));
        default:
      }
      return results;
    }, []);
    return items;
  }

  createBloodhoundTag(type = '') {
    return type
      .split('|')
      .slice(-1)
      .join('')
      .toLowerCase();
  }

  buildRecentSearches(collection) {
    if (!collection || !collection.length) {
      return [];
    }
    const recentSearches = collection.map((item, n) => {
      const {
        value = null,
        subValue = null,
        level = null,
        permalink = null,
        channel = null,
      } = item;
      return {
        isRecentSearchItem: true,
        value,
        n,
        level,
        permalink,
        channel,
        bhc: this.prepareBloodhoundTag(value),
        subValue,
      };
    });

    return [
      { isRecentSearchHeader: true },
      {
        isRecentSearches: true,
        displayAltRecentSearch: this.experiments
          ? this.experiments.showAlt
          : false,
        recentSearches,
        truncate: recentSearches.length > 3,
      },
    ];
  }

  // build the autocomplete view for location search
  buildLocationView(collections, query, config = {}) {
    const data = Object.assign(
      this.getDefaultData(collections, query, config),
      this.buildLocationItems(collections, query, config)
    );

    const partials = {
      currentLocationPartial,
      recentLocationPartial,
      clearingPartial,
      autocompletePartial,
    };

    return locationTemplate.render(data, partials);
  }

  buildLocationItems(collections, query) {
    let isGeoLocation = false;
    const items = collections.reduce((results, { collection, widget }) => {
      switch (widget) {
        case 'geolocation':
          isGeoLocation = true;
          break;
        case 'recentLocations':
          return results.concat(this.buildRecentLocations(collection));
        case 'autocomplete':
          return results.concat(this.buildAutocomplete(collection, query));
        default:
      }
      return results;
    }, []);
    return {
      items,
      isGeoLocation,
    };
  }

  // recent location list
  buildRecentLocations(collection) {
    if (!collection || !collection.length) {
      return [];
    }

    const items = collection.map((item, n) => {
      const { address } = item;
      // set value as full address
      const value = address;
      // display address without zipcode and country
      const label = this.deEmphasis(address, 3);
      return Object.assign({}, item, {
        isRecentLocationItem: true,
        n,
        value,
        label,
        bhc: this.prepareBloodhoundTag(address),
      });
    });
    items.unshift({ isRecentLocationHeader: true });

    return items;
  }

  buildAutocomplete(collection, query) {
    if (!collection || !collection.length) {
      return [];
    }

    return collection.map((item, n) => {
      let { value, label, address } = item;
      let unformattedValue = value;
      if (item.lat && item.neighborhood) {
        label = this.deEmphasis(label);
      }

      if (item.locationId && address) {
        value = address;
        address = highlightWords(address, matchesFuzzy(query, address, true));
        label = this.deEmphasis(address);
      } else {
        unformattedValue = value.replace(/<.*?>/g, '');
      }
      return Object.assign({}, item, {
        isAutocomplete: true,
        n,
        value,
        address,
        label,
        unformattedValue,
        highlight: normalizeQuery(value) === query,
      });
    });
  }
}

module.exports = TypeAHeadBuilder;
