'use strict'

{ fixLatOrLng } = require './m_fix_lat_lng'

FIVE_MINUTES_IN_MS = 1000 * 60 * 5
geoStorageKey = 'LS_geolocation'

{ getTranslations } = require './m_translations'
{ pick, uniq, isEmpty } = require '../../general/m_underscore_replacements'

Division = do (currentDivision = Groupon.division) ->
  get: ->
    currentDivision
  set: (division) ->
    currentDivision = division

###*
# Get decoded address
#
# @param   {String} [address] - Encoded address string
# @returns {String}           - Key value or empty object
###
decodeAddress = (address = '') ->
  decodeURIComponent(address).replace(/\+/g, ' ')

###*
# Retrieve geolocation data from localStorage
#
# @param   {String}        [key] - Key name (optional)
# @returns {String|Object} data  - Geodata object or specific data
###
getGeolocationData = (key) ->
  try
    if (data = window.localStorage?.getItem(geoStorageKey))?
      data = JSON.parse(data)
      if typeof key is 'string'
        data = data[ key ]
  data or {}

###*
# Retrieve location name from various sources
#
# @returns {String} location - Location name or empty string
###
getInitialLocation = ->
  divisionData = Division.get()
  location = ''

  return location if (location = getFullAddressFromSearchLocCookie())
  return location if (location = userFriendlyLocation(divisionData))
  return ''

###*
# Get location data
#
# @returns {Object} latLong - Location object
###
getInitialLocationData = ->
  grab = (sources..., properties) ->
    for source in sources
      data = pick(source or {}, properties)
      break  unless isEmpty data
    data

  queryParams = Groupon.Utils.unparam()
  locationData = getUserSearchLocData()

  latLong = grab queryParams, locationData, Division.get(), ['lat', 'lng']
  geoData = grab queryParams, locationData, [
    'administrative_area'
    'locality'
    'sublocality'
    'neighborhood'
    'postal_code'
  ]

  latLong = Object.assign latLong, geoData

###*
# Get "search_loc" cookie data
#
# @returns {Object} - search_loc - Search_loc data
###
getSearchLocCookie = ->
  search_loc = {}
  if (payload = Cookie.get('search_loc'))?
    try
      # itier pull app code (from test) seem to indicate this order for getting back the value
      search_loc = JSON.parse(decodeURIComponent(payload))
    catch
      try
        # old PWA seem to encode the cookie in this order...
        search_loc = decodeURIComponent(JSON.parse(payload))
  search_loc

###*
# Retrieve address from "search_loc" cookie
#
# @returns {String} - Address or empty string
###
getFullAddressFromSearchLocCookie = ->
  { fullAddress, friendlyName, currentLocation } = getSearchLocCookie()

  if currentLocation
    getTranslations('search')?.nearme
  else
    decodeAddress(fullAddress) or decodeAddress(friendlyName)

###*
# Retrieve data from "search_loc" cookie
#
# @returns {Object} - Relevant location data
###
getUserSearchLocData = ->
  search_loc = getSearchLocCookie()
  pick search_loc, [
    'lat'
    'lng'
    'administrative_area'
    'locality'
    'sublocality'
    'neighborhood'
    'postal_code'
  ]

###*
# Changes address to remove duplicate address sections and country "USA"
#
# @param   {String} address - Address returned by Google Maps
# @returns {String}         - Formatted address
###
presentAddress = (address) ->
  address = address.replace(/(\s\d{5})?,\sUSA$/, '') # try to remove zipcode + USA

  splitAddress = address.split(', ')
  uniq(splitAddress).join(', ')

###*
# Removes geolocation key from localStorage / cookie
#
# @param {String} key - LocalStorage key name
###
removeGeolocationData = (key) ->
  if key?
    data = getGeolocationData() # get data
    delete data[ key ]
    data = JSON.stringify data
    try
      window.localStorage?.setItem(geoStorageKey, data)
    catch
      Cookie.set geoStorageKey, encodeURIComponent(data)
  return

###*
# LS-2274 Set new location cookie "ell"
#
# @param {String} data - String contain <lat>,<lng>
###
setELLCookie = (data) ->
  Cookie.set 'ell', data, { consentClass: "essential" }
  return

setELLFromSearchLoc = ->
  unless Cookie.get 'ell'
    data = getLatLngSearchLoc()
    setELLCookie(data) if data
  return

###
# LS-2273 current location cookie "cll"
###
cllCookie = {

  ###*
  # LS-2273 Set new current location cookie "cll"
  #
  # @param {Object} location - Object containing coordinates
  ###
  set: (location) ->
    return unless location?.coords
    { latitude: lat, longitude: lng } = location.coords
    # Limit lat/lng to 3 decimal
    lat = parseFloat(lat.toFixed(3))
    lng = parseFloat(lng.toFixed(3))
    Cookie.set 'cll', lat + ',' + lng, { consentClass: "essential" }

    TTL = Date.now() + FIVE_MINUTES_IN_MS

    try
      localStorage?.setItem('cll_TTL', TTL)
    catch

    return

  ###
  # LS-2273 remove current location cookie "cll"
  ###
  remove: -> Cookie.unset 'cll'
}

getLatLngPayload = ->
  if division = (Division.get() or Groupon.division)
    division.lat + ',' + division.lng
  else
    ''

getLatLngSearchLoc = ->
  {lat, lng} = getSearchLocCookie()

  if lat and lng
    lat + ',' + lng
  else
    ''

###*
# Sets geolocation key/value pair to localStorage / cookie
#
# @param {String} key   - LocalStorage key name
# @param {String} value - Value to be set
###
setGeolocationData = (key, value) ->
  if key? and value?
    # get current data
    data = getGeolocationData()
    data[ key ] = value
    # set data
    try
      window.localStorage?.setItem(geoStorageKey, JSON.stringify(data))
  return

filterLocationData = (data) ->
  # defining what we store
  pick data, [
    'lat'
    'lng'
    'closestDivision'
    'friendlyName'
    'fullAddress'
    'administrative_area'
    'locality'
    'sublocality'
    'neighborhood'
    'postal_code'
    'currentLocation'
  ]

###*
# Sets "search_loc" cookie storing various data gathered from Gmaps and API responses
#
# @param {Object} data - Location data object
###
setSearchLocCookie = (data) ->
  # defining what we store
  data = filterLocationData(data)
  Cookie.set 'search_loc', encodeURIComponent(JSON.stringify data), { consentClass: 'essential' }
  return

###*
# Returns Division Name
#
# @param   {Object} division - Division from itier division data
# @returns {String} name     - Division name
###
userFriendlyLocation = (division) ->
  return '' unless division?.name
  { name, state } = division
  if abbreviation = state?.abbreviation
    name = "#{name}, #{abbreviation}"
  name

fixLocationCookies = (payload) ->
  if not (payload and typeof payload is 'string')
    return ''
  [lat, lng] = decodeURIComponent(payload).split(',')
  lat = fixLatOrLng(lat)
  lng = fixLatOrLng(lng)
  unless lat and lng
    return ''

  "#{lat},#{lng}"

module.exports = {
  cllCookie
  decodeAddress
  filterLocationData
  fixLatOrLng
  fixLocationCookies
  getDivision: Division.get
  getGeolocationData
  getFullAddressFromSearchLocCookie
  getInitialLocation
  getInitialLocationData
  getLatLngPayload
  getLatLngSearchLoc
  getSearchLocCookie
  getUserSearchLocData
  presentAddress
  removeGeolocationData
  setDivision: Division.set
  setELLCookie
  setELLFromSearchLoc
  setGeolocationData
  setSearchLocCookie
  userFriendlyLocation
}
